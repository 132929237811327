import { CrownOutlined, EditOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, Row, Switch, Tag, Tooltip, Typography, message } from 'antd';
import UserDetailsForm from 'components/admin/users/user-details-form';
import { useAuthContext } from 'components/auth-context';
import { BreadcrumbBar } from 'components/bread-crumb-bar';
import { NuButton, NuCard, NuPaper, Spacer } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import PhoneNumberAnchor from 'components/phone-number-anchor';
import UserAuthenticationTypeDisplay from 'components/user-authentication-type-display';
import {
  ClientRunbookEscalationsInlineInputs,
  USERS_CLIENT_RUNBOOK_ESCALATION_ORDER_QUERY,
} from 'components/users/client-runbook-escalations';
import { PermissionsCard } from 'components/users/permissions/permissions-card';
import { ADD_MEMBER } from 'components/users/user-group-detail/user-group-members/add-users-to-group-btn/add-users-to-group-btn';
import { REMOVE_USER_GROUP_MEMBER } from 'components/users/user-group-detail/user-group-members/user-group-members';
import SyncUserProfileButton from 'components/users/user-management/sync-user-profile-button';
import UserActionButtons from 'components/users/user-management/user-action-buttons';
import UserStatus from 'components/users/user-management/user-status';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { IPermissions, IUser, UserAuthenticationType } from 'types';
import {
  UserForAdminQuery,
  UserForAdminQueryVariables,
  UserGroupsForUserAdminQuery,
  UserGroupsForUserAdminQueryVariables,
  UsersClientRunbookEscalationOrderQuery,
  UsersClientRunbookEscalationOrderQueryVariables,
} from 'types/graph-codegen/graph-types';
import { config } from '../../../config';
import { personas } from '../../../personas';
import { formatUserName } from '../../../utils/users';
import { DetailsItem } from '../../details-item';
import { Content } from '../../layouts/content';
import { UserImpersonationButton } from './user-impersonation-button';

export const USER_QUERY = gql`
  query UserForAdmin($userId: String!) {
    getUserForAdmin(userId: $userId) {
      id
      createdAt
      clientId
      email
      login
      firstName
      mobilePhoneNumber
      mobilePhoneNumberCountry
      persona
      phoneNumber
      phoneNumberCountry
      phoneNumberExt
      title
      isSuperUser
      lastName
      authenticationType
      client {
        name
        effectivePermissions(userId: $userId)
      }
      serviceNowUserId
      deletedAt
      status
      managedClientIds
      managedClients {
        id
        name
      }
    }
  }
`;

export const GLOBAL_USER_GROUPS_QUERY = gql`
  query UserGroupsForUserAdmin($clientId: String!) {
    globalUserGroups(clientId: $clientId) {
      id
      createdAt
      description
      isHidden
      isSystemControlled
      name
      updatedAt
      members(clientId: $clientId) {
        id
        firstName
        lastName
        email
      }
      roles {
        id
        name
        permissions
      }
    }
  }
`;

const UPDATE_USER_PERMISSIONS = gql`
  mutation updateUserPermissions($userId: String!, $isSuperUser: Boolean) {
    updateUserPermissions(userId: $userId, isSuperUser: $isSuperUser) {
      id
      clientId
      email
      isSuperUser
    }
  }
`;

const GroupContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`;

const BtnContainer = styled.div`
  margin: auto 0 auto auto;
`;

const ManagedClientsList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  & > li:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export function UserDetails() {
  const { userId } = useParams<{ userId: string }>();
  const location = useLocation();
  // sent from users table to persist search state through the breadcrumb bar
  // @ts-ignore
  const previousSearchValue: string | undefined = location?.state?.search;
  const { data, error, loading, refetch } = useQuery<UserForAdminQuery, UserForAdminQueryVariables>(USER_QUERY, {
    variables: { userId: userId ?? '' },
    skip: !userId,
  });
  const [getUserGroups, { data: userGroupsData, loading: userGroupsLoading, error: userGroupsError }] = useLazyQuery<
    UserGroupsForUserAdminQuery,
    UserGroupsForUserAdminQueryVariables
  >(GLOBAL_USER_GROUPS_QUERY);
  const user = data?.getUserForAdmin;
  const permissions = user?.client?.effectivePermissions as IPermissions;
  const userTitle = user ? `${user!.firstName} ${user!.lastName}` : 'User Details';
  const memberOf = userGroupsData?.globalUserGroups!.filter((g) => g!.members!.find((m) => m!.id === userId));

  useEffect(() => {
    if (userGroupsError) {
      console.error(userGroupsError);
      message.error('An error occurred while attempting to fetch user groups');
    }
  }, [userGroupsError]);

  useEffect(() => {
    if (data?.getUserForAdmin?.clientId) {
      getUserGroups({
        variables: {
          clientId: data?.getUserForAdmin?.clientId,
        },
      });
    }
  }, [data?.getUserForAdmin?.clientId]);

  const breadCrumbBar = (
    <BreadcrumbBar
      items={[
        { text: 'Home', to: '/' },
        { text: 'Admin', to: '/admin' },
        { text: 'Users', to: previousSearchValue ? `/admin/users?search=${previousSearchValue}` : '/admin/users' },
        { text: userTitle, to: `/users/${user?.id}` },
      ]}
      isLoading={loading}
      error={error}
    />
  );

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <Content>
      <Helmet title={userTitle} />
      {breadCrumbBar}
      <Row gutter={[24, 24]} style={{ marginTop: '32px' }}>
        <Col span={24} xl={{ span: 12 }}>
          <AdminUserProfile
            memberOfGroupNames={memberOf?.map((g) => g!.name)?.join(', ')}
            refetch={() => refetch({ userId })}
            user={user as IUser}
          />
        </Col>
        <Col span={24} xl={{ span: 12 }}>
          <PermissionsCard permissions={permissions ?? []} />
        </Col>
        <Col span={24}>
          {userGroupsLoading && (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          )}
          {!userGroupsLoading && userGroupsData?.globalUserGroups && (
            <NuCard title="User Groups">
              <Row gutter={24}>
                <UserGroupsList
                  clientId={data?.getUserForAdmin?.clientId ?? ''}
                  loading={loading}
                  refetch={() => {
                    refetch({ userId });
                    if (data?.getUserForAdmin?.clientId) {
                      getUserGroups({
                        variables: {
                          clientId: data?.getUserForAdmin?.clientId,
                        },
                      });
                    }
                  }}
                  user={user as IUser}
                  userGroups={userGroupsData?.globalUserGroups}
                />
              </Row>
            </NuCard>
          )}
        </Col>
      </Row>
    </Content>
  );
}

type AdminUserProfileProps = {
  memberOfGroupNames?: string;
  refetch: () => void;
  user: IUser;
};

export function AdminUserProfile(props: AdminUserProfileProps) {
  const { memberOfGroupNames, refetch, user } = props;

  const [isEditing, setIsEditing] = useState(false);
  const { user: authUser } = useAuthContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: clientRunbookEscalationsData,
    loading: clientRunbookEscalationsLoading,
    refetch: refetchClientRunbookEscalations,
  } = useQuery<UsersClientRunbookEscalationOrderQuery, UsersClientRunbookEscalationOrderQueryVariables>(
    USERS_CLIENT_RUNBOOK_ESCALATION_ORDER_QUERY,
    {
      variables: {
        userId: user?.id,
      },
      skip: !user,
    },
  );
  const [updateUserPermissions, { loading: updating }] = useMutation(UPDATE_USER_PERMISSIONS);

  const isNotAuthUser = user?.id !== authUser?.id;
  const clientRunbookEscalations = clientRunbookEscalationsData?.clientRunbookEscalationsForUser;

  if (isEditing) {
    return <UserDetailsForm user={user as IUser} setIsEditing={setIsEditing} refetch={refetch} />;
  }

  return (
    <NuCard title="User Details">
      <Avatar
        name={formatUserName(user)}
        round
        color={theme.color.primary}
        email={user?.email ?? undefined}
        size="150px"
      />
      <Spacer height="2rem" />
      <DetailsItem title="ID" value={user!.id} showCopy />
      <DetailsItem
        title="Name"
        value={`${user!.firstName} ${user!.lastName}`}
        trailing={
          <>
            {user?.isSuperUser && (
              <Tooltip overlay="This user is a superuser">
                <CrownOutlined style={{ marginRight: '0.5rem' }} />
              </Tooltip>
            )}
            {user?.deletedAt && (
              <Tag color="red" style={{ marginLeft: user?.isSuperUser ? '0.25rem' : undefined }}>
                Deleted
              </Tag>
            )}
          </>
        }
      />
      <DetailsItem title="Login" value={user!.login ?? 'Unknown'} showCopy />
      <DetailsItem
        title="Email"
        value={<a href={`mailto:${user!.email}`}>{user!.email}</a>}
        showCopy
        altCopyText={user!.email}
      />
      <DetailsItem
        title="Created On"
        value={
          <Tooltip overlay={user!.createdAt}>
            {user?.createdAt ? dayjs(user!.createdAt).format('MM/DD/YYYY') : ''}
          </Tooltip>
        }
      />
      <DetailsItem title="Status" value={<UserStatus status={user!.status} />} />
      <DetailsItem
        title="Authentication Type"
        value={
          <UserAuthenticationTypeDisplay
            authenticationType={user!.authenticationType as UserAuthenticationType | undefined}
          />
        }
      />
      <DetailsItem
        title="Business Phone"
        value={
          <PhoneNumberAnchor
            phoneNumber={user?.phoneNumber ?? undefined}
            extension={user?.phoneNumberExt ?? undefined}
          />
        }
      />
      <DetailsItem
        title="Mobile Phone"
        value={<PhoneNumberAnchor phoneNumber={user?.mobilePhoneNumber ?? undefined} />}
      />
      <DetailsItem title="Title" value={user?.title} />
      <DetailsItem
        title="Runbook Escalations"
        value={
          <ClientRunbookEscalationsInlineInputs
            clientRunbookEscalations={clientRunbookEscalations}
            loading={clientRunbookEscalationsLoading}
            onSuccess={refetchClientRunbookEscalations}
            userId={user?.id}
          />
        }
      />
      <DetailsItem title="Persona" value={personas[user!.persona ?? 'unknown'] ?? 'N/A'} />
      <DetailsItem title="Client" value={<Link to={`/admin/clients/${user!.clientId}`}>{user!.client!.name}</Link>} />
      <DetailsItem
        title="Managed Clients"
        value={
          <ManagedClientsList>
            {user?.managedClients?.map((c) => (
              <li key={c?.id}>
                <Link to={`/admin/clients/${c?.id}`}>{c?.name}</Link>
              </li>
            ))}
          </ManagedClientsList>
        }
      />
      <DetailsItem title="Groups" value={memberOfGroupNames ?? 'None'} />
      <DetailsItem
        title="ServiceNow User ID"
        value={
          <a
            href={`${config.serviceNowBaseUrl}/nav_to.do?uri=sys_user.do?sys_id=${
              user!.serviceNowUserId
            }%26sysparm_view=case`}
            target="_blank"
            rel="noreferrer"
          >
            {user!.serviceNowUserId}
          </a>
        }
        showCopy
        altCopyText={user!.serviceNowUserId}
      />
      <DetailsItem
        title="Super User"
        value={
          <Switch
            loading={updating}
            disabled={updating}
            checked={user?.isSuperUser ?? false}
            onChange={async () => {
              try {
                await updateUserPermissions({ variables: { userId: user.id, isSuperUser: !user!.isSuperUser } });
                refetch();
              } catch (err) {
                console.error(err);
              }
            }}
          />
        }
      />

      <div style={{ marginTop: `1rem` }}>
        <NuButton
          type="primary"
          onClick={() => setIsEditing(true)}
          icon={<EditOutlined style={{ fontSize: '18px' }} />}
          style={{ marginRight: `1rem` }}
        >
          Edit
        </NuButton>
        {user && isNotAuthUser && (
          <>
            <UserImpersonationButton buttonType="default" style={{ marginRight: '0.25rem' }} user={user as IUser}>
              Impersonate
            </UserImpersonationButton>

            <SyncUserProfileButton
              onSuccess={() => refetch()}
              style={{ marginRight: '0.25rem' }}
              user={user as IUser}
            />

            <UserActionButtons
              user={user as IUser}
              onActionSuccess={() => refetch()}
              onForceDelete={() => navigate(`/admin/users`)}
            />
          </>
        )}
      </div>
    </NuCard>
  );
}

type UserGroupsListProps = {
  clientId: string;
  loading: boolean;
  refetch: () => void;
  user: IUser;
  userGroups?: UserGroupsForUserAdminQuery['globalUserGroups'];
};

export function UserGroupsList(props: UserGroupsListProps) {
  const { clientId, loading, refetch, user, userGroups } = props;

  return (
    <>
      {userGroups?.map((g) => {
        const isMember = g!.members!.find((m) => m!.id === user?.id);
        return (
          <Col span={24} key={g!.id} style={{ marginBottom: '24px' }}>
            <NuPaper>
              <GroupContainer>
                <Typography.Title
                  level={4}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {g!.name}
                </Typography.Title>
                <Typography.Text style={{ paddingLeft: '5px' }}>
                  {'- '}
                  {isMember ? 'member' : 'non-member'}
                </Typography.Text>
                <BtnContainer>
                  {isMember ? (
                    <RemoveUserFromUserGroupButton
                      clientId={clientId}
                      loading={loading}
                      refetch={refetch}
                      userGroupId={g!.id}
                      userEmail={user?.email}
                      userId={user.id}
                    />
                  ) : (
                    <AddUserToUserGroupButton
                      clientId={clientId}
                      loading={loading}
                      refetch={refetch}
                      userGroupId={g!.id}
                      userEmail={user?.email}
                      userId={user.id}
                    />
                  )}
                </BtnContainer>
              </GroupContainer>
            </NuPaper>
          </Col>
        );
      })}
    </>
  );
}

type UserGroupActionButtonProps = {
  clientId: string;
  loading: boolean;
  refetch: (args: { clientId: string; userId: string }) => void;
  userEmail?: string;
  userId: string;
  userGroupId: string;
};

function RemoveUserFromUserGroupButton(props: UserGroupActionButtonProps) {
  const { clientId, loading, refetch, userEmail, userId, userGroupId } = props;

  const [removeMember, { loading: removing }] = useMutation(REMOVE_USER_GROUP_MEMBER);

  return (
    <NuButton
      type="default"
      disabled={loading || removing}
      loading={loading || removing}
      icon={<UsergroupDeleteOutlined />}
      onClick={async () => {
        try {
          const { data, errors } = await removeMember({
            variables: {
              clientId,
              userId,
              userGroupId,
            },
          });

          if (data?.removeUserGroupMember) {
            message.success(`User ${userEmail} successfully removed from user group!`);
            refetch({ clientId, userId });
          }

          if (errors && !!errors.length) {
            message.error(`An error occurred while removing ${userEmail ?? 'user'} from the user group.`);
            errors.forEach((e) =>
              console.error(`An error occurred while removing ${userEmail ?? 'user'} from the user group.`, {
                message: e.message,
              }),
            );
          }
        } catch (err) {
          console.error('An error occurred while removing user to user group', {
            message: err.message,
          });
          message.error(`An error occurred while removing ${userEmail ?? 'user'} from the user group.`);
        }
      }}
    >
      Remove
    </NuButton>
  );
}

function AddUserToUserGroupButton(props: UserGroupActionButtonProps) {
  const { clientId, loading, refetch, userEmail, userId, userGroupId } = props;

  const [addMember, { loading: adding }] = useMutation(ADD_MEMBER);

  return (
    <NuButton
      type="default"
      disabled={loading || adding}
      loading={loading || adding}
      icon={<UsergroupAddOutlined />}
      onClick={async () => {
        try {
          const { data, errors } = await addMember({
            variables: {
              clientId,
              userId,
              userGroupId,
            },
          });

          if (data?.addUserGroupMember) {
            message.success(`User ${userEmail} successfully added to user group!`);
            refetch({ clientId, userId });
          }

          if (errors && !!errors.length) {
            message.error(`An error occurred while adding ${userEmail ?? 'user'} to the user group.`);
            errors.forEach((e) =>
              console.error(`An error occurred while adding ${userEmail ?? 'user'} to the user group.`, {
                message: e.message,
              }),
            );
          }
        } catch (err) {
          console.error('An error occurred while adding user to user group', {
            message: err.message,
          });
          message.error(`An error occurred while adding ${userEmail ?? 'user'} to the user group.`);
        }
      }}
    >
      Add
    </NuButton>
  );
}
