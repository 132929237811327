import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security as OktaAuthProvider, useOktaAuth } from '@okta/okta-react';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CurrentUserProvider, useAuthContext } from './components/auth-context';
import { ClientContextProvider } from './components/client-context-provider';
import ClientTaskModalContextProvider from './components/client-tasks/client-task-modal-context-provider';
import { AuthenticatedLayout } from './components/layouts/authenticated-layout/authenticated-layout';
import { FullPageSpin } from './components/nuspire/full-page-spin';
import { REDIRECT_TO_KEY } from './localstorage';
import { getAccessToken, isTokenExpired } from './utils/access-tokens';
import { useMixpanelTimeOnPage } from './utils/mixpanel/mixpanel-page-timer';
import { oktaAuth as oktaAuthUtil } from './utils/okta';

const EnsureUserIsAuthenticated: FC<PropsWithChildren> = (props) => {
  const authCtx = useAuthContext();
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasValidAccessToken, setHasValidAccessToken] = useState<boolean>();
  const [sessionIsExpired, setSessionIsExpired] = useState<boolean>();

  useEffect(() => {
    // When we navigate to a new location and if we're not authenticated according to Okta,
    // see if we have a token stored.
    const checkHasValidAccessToken = async (): Promise<void> => {
      const token = await getAccessToken();
      const isValidToken: boolean = !!token && !isTokenExpired(token);
      const isValidExpiredToken: boolean = !!token && isTokenExpired(token);

      setHasValidAccessToken(isValidToken);
      setSessionIsExpired(isValidExpiredToken);
    };

    if (!authState?.isAuthenticated) {
      checkHasValidAccessToken();
    }
  }, [location]);

  useEffect(() => {
    if (!authState) {
      return;
    }

    // If we're not authenticated with Okta && we don't have any sort of token (normal, impersonation, internal),
    // prompt to log in.
    if (!authState.isAuthenticated && !hasValidAccessToken) {
      const redirectTo = `${location.pathname}${location.search?.trim()}`;
      window.localStorage.setItem(REDIRECT_TO_KEY, redirectTo);
      navigate(`/login${sessionIsExpired ? '?sessionExpired=true' : ''}`);
    }
  }, [oktaAuth, authState, authState?.isAuthenticated, hasValidAccessToken]);

  if (authCtx.error) {
    console.error(authCtx.error);
    throw new Error('An error occurred while attempting to load your user information.');
  }

  // If we're not authenticated with Okta && we don't have any sort of token (normal, impersonation, internal),
  // display a loading message.
  if (!authState?.isAuthenticated && !hasValidAccessToken && !authCtx.user) {
    return <FullPageSpin tip="Authenticating User..." />;
  }

  return props.children;
};

const ClientContextLayout: FC<PropsWithChildren> = (props) => {
  const { user, loading: isAuthContextLoading } = useAuthContext();
  const location = useLocation();
  useMixpanelTimeOnPage();
  const params = useParams<{ clientId: string }>();

  const clientId = params.clientId ?? user?.clientId;

  if (isAuthContextLoading) {
    return <FullPageSpin tip="Authenticating User..." />;
  }

  if (!clientId) {
    console.warn('No client ID could be determined. Not wrapping children in ClientContextProvider');
    return props.children;
  }

  // /:clientId -> /:clientId/home
  if (location.pathname.trim() === `/${clientId}`) {
    return <Navigate to={`/${clientId}/home`} replace />;
  }

  return (
    <ClientContextProvider clientId={clientId}>
      <ClientTaskModalContextProvider>{props.children}</ClientTaskModalContextProvider>
    </ClientContextProvider>
  );
};

export const RequireAuthentication: FC = () => {
  const navigate = useNavigate();

  return (
    <OktaAuthProvider
      oktaAuth={oktaAuthUtil}
      restoreOriginalUri={(_oktaAuth, originalUri) => {
        if (!originalUri) {
          return;
        }

        const url = toRelativeUrl(originalUri, window.location.origin);
        if (url) {
          navigate(url, { replace: true });
        }
      }}
    >
      <CurrentUserProvider>
        <EnsureUserIsAuthenticated>
          <ClientContextLayout>
            <AuthenticatedLayout>
              <Outlet />
            </AuthenticatedLayout>
          </ClientContextLayout>
        </EnsureUserIsAuthenticated>
      </CurrentUserProvider>
    </OktaAuthProvider>
  );
};
