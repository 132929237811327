import { Modal, Typography, Form, Tooltip, Result } from 'antd';
import { LockOutlined, UnlockOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { CopyToClipboardIcon } from 'components/shared-components';
import { gql, useMutation } from '@apollo/client';
import { NuButton } from 'components/nuspire';
import { useState } from 'react';
import ConnectionInput from 'components/cyberx/actions/action-form/action-form-field/inputs/connection-input';
import { useClientContext } from 'components/client-context-provider';
import { ActionFormFieldDef } from 'components/cyberx/actions/types';

interface ConnectionsModalProps {
  visible: boolean;
  onCloseModal?: (e) => void;
  userPrincipalName: string;
}

interface ActionProps {
  userPrincipalName: string;
  actionSlug: string;
  closeModal: ((e) => void) | undefined;
}

enum ActionSlugs {
  LOCKOUT_USER = 'azure-lockout-user',
  REVOKE_USER = 'azure-revoke-user-sessions',
  UNLOCK_RESET = 'azure-unlock-and-reset-user',
}

const ACTION_MUTATION = gql`
  mutation ExectueAction($clientId: String!, $actionId: String!, $input: JSONObject!) {
    executeAction(clientId: $clientId, actionId: $actionId, input: $input) {
      id
      ok
      summary
      data
    }
  }
`;

const connectionsField: ActionFormFieldDef = {
  key: 'connection',
  label: 'Connection',
  type: 'object',
  jsonSchemaString: `{
        properties: {
            id: { type: 'string' },
            name: { type: 'string' },
        },
    }`,

  required: true,
  inputType: 'connection',
  parameters: {
    connectorSlug: 'azure',
  },
  errorMessages: {
    required: 'Connection is required.',
  },
};

function ConnectionsForm(props: ActionProps) {
  const [connectionAction, { loading }] = useMutation(ACTION_MUTATION);
  const { userPrincipalName, actionSlug, closeModal } = props;
  const client = useClientContext();

  const [tempPassword, setTempPassword] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [ok, setOk] = useState<boolean | undefined>(undefined);

  if (!client) {
    throw Error(`no client present`);
  }

  function handleCloseModal(e): void {
    e.stopPropagation();
    if (closeModal) {
      closeModal(e);
      setStatus(undefined);
      setTempPassword(undefined);
    }
  }

  const onFinish = async (values) => {
    const input = {
      connectionId: values.connection?.id,
      userPrincipalName: userPrincipalName,
    };

    const result = await connectionAction({
      variables: {
        clientId: client.clientId,
        actionId: actionSlug,
        input,
      },
    });

    if (result.errors?.length) {
      setOk(false);
      return setStatus(result.errors?.map((error) => error.message)?.join('. '));
    }

    setOk(result.data.executeAction.ok);
    setStatus(result.data.executeAction.summary);
    if (result.data.executeAction.data?.tempPassword) {
      setTempPassword(result.data.executeAction.data.tempPassword);
    }

    return result;
  };

  if (!tempPassword && status && !ok) {
    return (
      <Result
        status="error"
        title="Action Result"
        extra={[
          <NuButton
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              setStatus(undefined);
              setTempPassword(undefined);
            }}
          >
            Try Again
          </NuButton>,
          <NuButton onClick={handleCloseModal}>Close</NuButton>,
        ]}
      >
        <Typography.Text>{status}</Typography.Text>
      </Result>
    );
  }

  if (ok && status) {
    return (
      <Result status="success" title="Action Result" extra={[<NuButton onClick={handleCloseModal}>Close</NuButton>]}>
        <Typography.Text>{status}</Typography.Text>

        {tempPassword ? (
          <>
            <br />
            <Typography.Text>Here is your temporary password for {userPrincipalName}</Typography.Text>
            <br />
            <Typography.Text>
              <strong>Password: </strong>
              {tempPassword} <CopyToClipboardIcon copyText={tempPassword} />
            </Typography.Text>
          </>
        ) : null}
      </Result>
    );
  }

  return (
    <>
      <Typography.Paragraph>Please select the connection you'd like to use for this call</Typography.Paragraph>
      <Form onFinish={onFinish}>
        <Form.Item name="connection" rules={[{ required: true, message: 'Please select a connection.' }]}>
          <ConnectionInput
            clientId={client.clientId}
            clientName={client.client?.name}
            name="connection"
            field={connectionsField}
            // `onChange` is required but not used. not sure why
            onChange={(_val) => {}}
          />
        </Form.Item>
        <Form.Item>
          <NuButton htmlType="submit" type="primary" loading={loading} onClick={(e) => e.stopPropagation()}>
            Take Action
          </NuButton>
        </Form.Item>
      </Form>
    </>
  );
}

function LockUserConnectionsModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Lock User Connections Modal" width={1200} footer={false} onCancel={onCloseModal}>
      <ConnectionsForm
        userPrincipalName={userPrincipalName}
        actionSlug={ActionSlugs.LOCKOUT_USER}
        closeModal={onCloseModal}
      />
    </Modal>
  );
}

function UnlockUserConnectionsModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Unlock User Connections Modal" width={1200} footer={false} onCancel={onCloseModal}>
      <ConnectionsForm
        userPrincipalName={userPrincipalName}
        actionSlug={ActionSlugs.UNLOCK_RESET}
        closeModal={onCloseModal}
      />
    </Modal>
  );
}
function RevokeUserSessionModal(props: ConnectionsModalProps) {
  let { visible, onCloseModal, userPrincipalName } = props;

  return (
    <Modal open={visible} title="Revoke User Connections Modal" width={1200} footer={false} onCancel={onCloseModal}>
      <ConnectionsForm
        userPrincipalName={userPrincipalName}
        actionSlug={ActionSlugs.REVOKE_USER}
        closeModal={onCloseModal}
      />
    </Modal>
  );
}

export function AzureLockoutUser(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };

  const userPrincipalName = record.userPrincipalName;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in locking users out">
        <NuButton icon={<LockOutlined />} type="primary" onClick={openModal} style={{ marginRight: '0.25rem' }} />
      </Tooltip>
      <LockUserConnectionsModal
        visible={showModal}
        onCloseModal={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function AzureUnlockResetUser(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };
  const userPrincipalName = record.userPrincipalName;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in unlocking users">
        <NuButton icon={<UnlockOutlined />} type="primary" onClick={openModal} style={{ marginTop: '0.25rem' }} />
      </Tooltip>
      <UnlockUserConnectionsModal
        visible={showModal}
        onCloseModal={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function AzureRevokeUserSession(props: { value: any; record: any }) {
  const { value, record } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };
  const userPrincipalName = record.userPrincipalName;

  return (
    //if button is clicked and multiple connections are found, allow the user to select which connection to use
    <>
      <Tooltip overlay="Clicking this will assist you in revoking this current users sessions">
        <NuButton icon={<UserDeleteOutlined />} type="primary" onClick={openModal} style={{ marginRight: '0.25rem' }} />
      </Tooltip>
      <RevokeUserSessionModal
        visible={showModal}
        onCloseModal={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
        userPrincipalName={userPrincipalName}
      />
    </>
  );
}

export function Office365ActionsComponent(props: { value: any; record: any }) {
  const { value, record } = props;

  return (
    <>
      <AzureLockoutUser value={value} record={record} />
      <AzureRevokeUserSession value={value} record={record} />
      <AzureUnlockResetUser value={value} record={record} />
    </>
  );
}
export function AzureActionsComponent(props: { value: any; record: any }) {
  const { value, record } = props;

  return (
    <>
      <AzureLockoutUser value={value} record={record} />
      <AzureRevokeUserSession value={value} record={record} />
      <AzureUnlockResetUser value={value} record={record} />
    </>
  );
}
export const renderComponentsMap = {
  Office365ActionsComponent,
  AzureActionsComponent,
};
