import { Space } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import { Link, NuButton } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import { useEffect, useState } from 'react';
import { WidgetComponentProps } from '../..';

function getLinkTo(args: { clientId: string | undefined; tags: string | null }) {
  const { clientId, tags } = args;

  let to = `/${clientId}/case-management/tasks`;

  if (tags) {
    const sp = new URLSearchParams();
    sp.set('tags', tags);

    to += `?${sp.toString()}`;
  }

  return to;
}

const TaskListWidget = (props: WidgetComponentProps<any, any> /* type these once we get real data */) => {
  const { data, setSubAction, onFetch, configuration, settings } = props;
  const { clientId } = useClientContext();

  const [dataSource, setDataSource] = useState<any>(data?.tasks ?? []);
  const [next, setNext] = useState<string | undefined>(data?.next);
  const [total, setTotal] = useState<number | undefined>(data?.total);

  const tags = settings?.tags?.length ? settings.tags : null;

  const linkTo = getLinkTo({ tags, clientId });

  const handleFetch = async () => {
    const data = await onFetch?.({
      variables: { start: next },
    });

    const items = data?.tasks;

    if (items?.length) {
      if (items) {
        const newDataSource = [...dataSource, ...items];

        setDataSource(newDataSource);
      }

      setNext(data?.next);
      setTotal(data?.total);
    } else {
      setNext(undefined);
      setTotal(undefined);
    }
  };

  useEffect(() => {
    if (setSubAction)
      setSubAction(
        <Link to={linkTo} style={{ marginRight: '1rem' }} mode="plain">
          View all
        </Link>,
      );
  }, []);

  useEffect(() => {
    setDataSource(data?.tasks ?? []);
  }, [data]);

  if (!data) {
    return <EmptyState>No tasks at this time.</EmptyState>;
  }

  const tasksAvailable = dataSource.length > 0;
  const showLoadMoreBtn = next && total && total > dataSource?.length;

  return tasksAvailable ? (
    // return a list of tasks if we have some
    <Space direction="vertical" style={{ display: 'flex', alignItems: 'stretch' }}>
      {dataSource?.map((t) => <ClientTaskCard key={t.id} {...t} />)}

      {showLoadMoreBtn ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <NuButton onClick={handleFetch}>Load More</NuButton>
        </div>
      ) : null}
    </Space>
  ) : (
    // otherwise be clear none are available
    <EmptyState>No tasks at this time.</EmptyState>
  );
};

export default TaskListWidget;
