import { useState } from 'react';
import Breadcrumb from 'components/nuspire/nu-breadcrumb';
import PageHeader from 'components/nuspire/nu-page-header';
import { useClientContext } from 'components/client-context-provider';
import { Form, Input, Select, message, Radio, Space, RadioChangeEvent, Typography, App } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Link, NuButton } from 'components/nuspire';
import { getTaskStatusMenuItems } from './client-task-card';
import { gql, useMutation } from '@apollo/client';
import { Content } from '../layouts/content';
import { CreateClientTaskMutation } from 'types/graph-codegen/graph-types';

const CREATE_TASK = gql`
  mutation CreateClientTask(
    $clientId: String!
    $label: String!
    $summary: String
    $type: ClientTaskType!
    $status: ClientTaskStatus!
    $impact: String
    $remediation: String
    $info: String
    $tags: [String!]
    $priority: Int
    $snowCaseNumber: String
  ) {
    createClientTask(
      clientId: $clientId
      label: $label
      type: $type

      status: $status
      summary: $summary
      impact: $impact
      remediation: $remediation
      info: $info
      tags: $tags
      priority: $priority
      snowCaseNumber: $snowCaseNumber
    ) {
      id
      type
      shortId
    }
  }
`;

const CREATE_CHILD_CLIENT_TASKS = gql`
  mutation CreateChildClientTasks(
    $clientId: String!
    $label: String!
    $summary: String
    $type: ClientTaskType!
    $status: ClientTaskStatus!
    $impact: String
    $remediation: String
    $info: String
    $tags: [String!]
    $priority: Int
    $snowCaseNumber: String
    $clientsFilters: [JSONObject!]
  ) {
    createChildClientTasks(
      clientId: $clientId
      label: $label
      type: $type

      status: $status
      summary: $summary
      impact: $impact
      remediation: $remediation
      info: $info
      tags: $tags
      priority: $priority
      snowCaseNumber: $snowCaseNumber
      clientsFilters: $clientsFilters
    ) {
      ok
      summary
      data
    }
  }
`;

function formatValues(values: any) {
  const { technology, ...task } = values;

  if (technology) {
    const technologyTag = `technology-source-${technology}`;

    task.tags = Array.isArray(task.tags) ? [...task.tags, technologyTag] : [technologyTag];
  }

  const esiemTechnologyFilters = task.clientsFilters?.filter((filter) => filter.key === 'esiem_technologies');
  if (esiemTechnologyFilters?.length) {
    // add tags.

    const tags = esiemTechnologyFilters.reduce((acc: string[], filter) => {
      // acc.push(...filter.value);

      acc.push(...filter.value.map((val) => `technology-source-${val}`).filter((tag) => !acc.includes(tag)));

      return acc;
    }, task.tags ?? []);

    task.tags = tags;
  }

  return task;
}

type ChildClientFilterProps = {
  row: number;
  restField: any;
};

export type ChildClientFilter = {
  key: string;
  label: string;
  component: (props: ChildClientFilterProps) => JSX.Element;
};

const CHILD_CLIENT_FILTERS: ChildClientFilter[] = [
  {
    key: 'esiem_technologies',
    label: 'eSiem Technologies Includes',
    component: EsiemTechnologiesSelect,
  },
];

function EsiemTechnologiesSelect(props: ChildClientFilterProps) {
  const { row, restField } = props;

  return (
    <Form.Item {...restField} name={[row, 'value']} rules={[{ required: true, message: 'Value is Required.' }]}>
      <Select
        placeholder="Select Technologies"
        options={TECHNOLOGY_OPTIONS}
        mode="multiple"
        style={{ width: '300px' }}
      />
    </Form.Item>
  );
}

function ChildClientFilterValue(props: { row: number; restField: any; type?: string }) {
  const { row, restField, type } = props;

  const filterDef = type ? CHILD_CLIENT_FILTERS.find((f) => f.key === type) : undefined;

  if (!filterDef) {
    return null;
  }

  const Component = filterDef.component;

  return <Component restField={restField} row={row} />;
}

function ChildClientFilterRow(props: { restField: any; row: number; onRemove: () => void }) {
  const { restField, row, onRemove } = props;

  const form = Form.useFormInstance();

  const [type, setType] = useState();

  const onTypeChange = (value) => {
    form.setFieldValue(['clientsFilters', row, 'key'], value);

    setType(value);
  };

  return (
    <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      <Form.Item {...restField} name={[row, 'key']} rules={[{ required: true }]}>
        <Select
          options={CHILD_CLIENT_FILTERS.map(({ key, label }) => ({
            value: key,
            label,
          }))}
          style={{ width: '300px' }}
          placeholder="Select Filter"
          onChange={onTypeChange}
        />
      </Form.Item>

      <ChildClientFilterValue type={type} row={row} restField={restField} />

      <MinusCircleOutlined onClick={onRemove} />
    </Space>
  );
}

function ChildClientFilters() {
  return (
    <Form.Item label="Filter Clients">
      <Form.List name="clientsFilters">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <ChildClientFilterRow key={key} restField={restField} row={name} onRemove={() => remove(name)} />
                );
              })}

              {/* Add Field Button */}
              <Form.Item>
                <NuButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Filter
                </NuButton>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
}

function buildChildClientSuccessMessage(
  results: {
    ok: boolean;
    summary: string;
    data?: {
      backgroundTask?: {
        id: string;
      };
    };
  },
  clientId?: string,
) {
  const { summary, data } = results;

  const backgroundTaskId = data?.backgroundTask?.id;

  const message = (
    <Typography.Text>
      <Typography>
        {summary}
        {clientId && Boolean(backgroundTaskId) && (
          <a href={`/${clientId}/automations/jobs/${backgroundTaskId}`}> Job {backgroundTaskId}</a>
        )}
      </Typography>
    </Typography.Text>
  );

  return message;
}

export type CreateTaskInitialValues = {
  type?: 'recommendation' | 'issue';
  currentOrChildClients?: 'current_client' | 'child_clients';
  label?: string;

  summary?: string;
  info?: string;
  status?: string;
  tags?: string[];
};

const DEFAULT_INITIAL_VALUES = { type: 'recommendation', currentOrChildClients: 'current_client' };

export type CreateClientTaskResult = NonNullable<CreateClientTaskMutation['createClientTask']>;

export const CreateClientTaskForm = (props: {
  /** if true, user will be able to select between creating a task for the current user vs all child clients. Defaults to false. */
  supportChildClientTaskCreation?: boolean;
  initialValues?: CreateTaskInitialValues;

  /** Callback for parent component to be able to trigger something after creation is complete. */
  onSuccess?: (task: CreateClientTaskResult) => void;
}) => {
  const { supportChildClientTaskCreation = true, initialValues = DEFAULT_INITIAL_VALUES, onSuccess } = props;

  const { clientId, client } = useClientContext();
  const { modal } = App.useApp();
  const [form] = Form.useForm();
  const [currentStatuses, setCurrentStatuses] = useState(getTaskStatusMenuItems(form.getFieldValue('type')));
  const [createTask, { loading }] = useMutation<CreateClientTaskMutation>(CREATE_TASK);
  const [createChildClientTasks, { loading: childClientTasksLoading }] = useMutation(CREATE_CHILD_CLIENT_TASKS);
  const [currentOrChildClients, setCurrentOrChildClients] = useState<string>('current_client');
  const isMultiTenancyEnabled = client?.isMultiTenancyEnabled;

  const onFinish = async ({ currentOrChildClients, ...values }) => {
    try {
      // Task To be created.
      const task = formatValues(values);

      if (currentOrChildClients === 'child_clients') {
        modal.confirm({
          title: 'Create Child Client Task Recommendations',
          content: 'Confirming will create Task for all child clients who pass the filters selected.',
          okText: 'Create',
          onOk: async () => {
            const result = await createChildClientTasks({
              variables: {
                ...task,
                clientId,
              },
            });
            const childClientTasksResult = result.data?.createChildClientTasks;

            if (!childClientTasksResult) {
              throw new Error(`There was an error creating task for child clients.`);
            }

            const successMessage = buildChildClientSuccessMessage(childClientTasksResult, clientId);

            message.success(successMessage);
          },
        });
      } else {
        // create task like normal.
        const result = await createTask({ variables: { ...task, clientId } });

        const newTask = result.data?.createClientTask;

        if (newTask) {
          const successMessage = (
            <Typography.Text>
              <Typography>Successfully created task! </Typography>
              {/* We need to build Client Task Detail View */}
              {/* <a href={`/${clientId}/case-management/tasks/${newTask.id}`}>{`${newTask.type} #${newTask.shortId}`}</a> */}
            </Typography.Text>
          );
          message.success(successMessage);

          // Call callback from parent component
          if (onSuccess) onSuccess(newTask);
        } else {
          message.error(`Failed to Create Task`);
        }
      }

      form.resetFields();
    } catch (error) {
      message.error(`Failed to create task, please try again.`);
      console.error(error);
    }

    setCurrentOrChildClients('current_client');
  };

  const onCurrentOrChildClientsChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    form.setFieldValue('currentOrChildClients', value);

    setCurrentOrChildClients(value);

    if (value !== 'child_clients') {
      // Clear Child Client Filter Values.
      form.setFieldValue('clientsFilters', undefined);
    }
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
      {/* Only render if client is multitenant */}

      {supportChildClientTaskCreation && isMultiTenancyEnabled && (
        <Form.Item label="Create Task for..." name="currentOrChildClients">
          <Radio.Group onChange={onCurrentOrChildClientsChange}>
            <Space direction="vertical">
              <Radio value="current_client">Current Client</Radio>
              <Radio value="child_clients">Child Clients</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      )}

      {currentOrChildClients === 'child_clients' && <ChildClientFilters />}

      <Form.Item name="label" rules={[{ required: true, message: 'Label is required' }]} label="Label">
        <Input />
      </Form.Item>
      <Form.Item
        name="summary"
        rules={[{ required: true, message: 'Description is required' }]}
        label="Description (Short)"
      >
        <Input />
      </Form.Item>

      <Form.Item name="info" label="Info" help="Supports markdown" style={{ marginBottom: '24px' }}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Type is required' }]}>
        <Select onChange={(value) => setCurrentStatuses(getTaskStatusMenuItems(value))}>
          {['recommendation', 'issue'].map((type) => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Status is required' }]}>
        <Select>
          {currentStatuses?.map((status) => (
            <Select.Option key={status.key} value={status.key}>
              {status.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remediation" label="Remediation">
        <Input />
      </Form.Item>
      <Form.Item name="impact" label="Impact">
        <Input />
      </Form.Item>
      <Form.Item name="priority" label="Priority">
        <Select>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((p) => (
            <Select.Option key={p} value={p}>
              {p}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="technology" label="Technology">
        <Select
          placeholder="Select Technology"
          options={[
            {
              label: 'None',
              value: 'none',
            },
            ...TECHNOLOGY_OPTIONS,
          ]}
        />
      </Form.Item>

      <Form.List name="tags">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? 'Tags' : ''} required={false} key={field.key}>
                <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                  <Input placeholder="Tag" style={{ width: 'calc(100% - 24px)' }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ fontSize: '16px', marginLeft: '.5rem' }}
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <NuButton onClick={() => add()} icon={<PlusOutlined />}>
                Add Tag
              </NuButton>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item name="snowCaseNumber" label="Case Number" tooltip="Link this task to a serviceNow Case">
        <Input />
      </Form.Item>
      <NuButton htmlType="submit" type="primary" loading={loading || childClientTasksLoading}>
        Create Task
      </NuButton>
    </Form>
  );
};

export const CreateClientTask = () => {
  const { clientId } = useClientContext();

  return (
    <Content>
      <Breadcrumb
        items={[
          {
            key: `${clientId}-case-management-tasks`,
            title: (
              <Link to={`/${clientId}/case-management?activeTab=tasks`} mode="plain">
                Case Management
              </Link>
            ),
          },
          {
            key: `${clientId}-case-management-tasks-page`,
            title: (
              <Link to={`/${clientId}/case-management/tasks`} mode="plain">
                Tasks
              </Link>
            ),
          },
          {
            key: 'create',
            title: 'Create',
          },
        ]}
        target="case-management"
      />

      <PageHeader title="Create Task" />

      <CreateClientTaskForm />
    </Content>
  );
};

export const TECHNOLOGY_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Windows',
    value: 'a4adc579-7ec2-48d5-8c58-8b8ab0712aa1',
  },
  {
    label: 'Fortinet',
    value: '3e663464-6882-4cdd-adb2-e72f02a6e746',
  },
  {
    label: 'Fortigate',
    value: 'e7bf1745-7053-4763-adc5-e77d417c540b',
  },
  {
    label: 'Palo Alto',
    value: '0d3635f6-6fb5-4dcf-841c-7bc75e9e6d5d',
  },
  {
    label: 'Meraki',
    value: 'c25541b2-3dfd-4f13-860f-8c90619fcd2d',
  },
  {
    label: 'CheckPoint',
    value: 'c2492ec8-d80e-45e5-bcd4-ac3c7f3e6a94',
  },
  {
    label: 'Sophos',
    value: 'adb11ffb-6722-4433-83e5-1188de2b3b9c',
  },
  {
    label: 'Barracuda',
    value: '54d38d75-fda6-42b0-87f9-af3a85a9ef52',
  },
  {
    label: 'ASA',
    value: 'c152442f-6f0c-4c63-ae9f-0b5f405e55dc',
  },
  {
    label: 'SonicWall',
    value: '28f6bddb-6c82-4e3c-8c40-763670a15df3',
  },
  {
    label: 'Watchguard',
    value: '40df498e-bce4-45bf-addc-8a2f01cc278e',
  },
  {
    label: 'O365/Azure',
    value: '5acc0c8d-2e3f-4cdd-aa37-f67f361ccf25',
  },
  {
    label: 'Zscaler',
    value: 'e5bf9ecc-fe7d-444b-9999-b39cd12f923c',
  },
  {
    label: 'Cisco Umbrella',
    value: '7b861b2e-90c1-4387-8bd7-7f1e68236d22',
  },
  {
    label: 'Cisco AMP',
    value: '1760c7e7-35a9-421a-855f-7af5bcc57a9b',
  },
  {
    label: 'Linux',
    value: '5f99573e-00b9-4e2f-9acc-fa3ce6470ca9',
  },
  {
    label: 'Drop Box',
    value: '172453c6-44eb-4792-8627-5c250262280e',
  },
  {
    label: 'Okta',
    value: 'f954554a-9559-455f-9553-2bc377b8c46b',
  },
  {
    label: 'Sentinel One',
    value: '1de179ef-9539-4f35-bf69-c48ce72088ae',
  },
  {
    label: 'SalesForce',
    value: 'c13b3e29-2211-4dbc-8405-8df509750dd0',
  },
  {
    label: 'Cato',
    value: '16ab33bf-a288-476f-abf4-ed7af752ab3d',
  },
];
