import { WidgetComponent } from '..';
import CustomFortisiemCountsWidget from './custom-fortisiem-counts';
import CountsDoughnut from './doughnut';
import CountPillar from './count-pillar';
import IndustryNews from './industry-news-widget';
import Line from './line';
import { MetaWidget } from './meta-widget';
import { MonthlyEventSummaryWidget } from './monthly-event-summary';
import ManagedAssetHealthWidget from './program/managed-asset-health';
import NuspireServicesWidget from './program/nuspire-services';
import SecurityScoreCardWidget from './program/security-score-card';
import ServiceRisk from './program/service-risk';
import ServiceValue from './program/service-value';
import ServiceNowRemediationsByPriorityWidget from './program/servicenow-remediations-by-priority';
import Statistics from './statistics';
import TaskList from './program/task-list';
import TopBlockedCategories from './program/top-blocked-categories';
import Table from './table';
import TechnologySourceDeviceWidget from './technology-source-device-widget';
import TechnologySourceWidget from './technology-source-widget';
import ThreatBriefs from './threat-briefs-widget';
import { SecurityProgramPosture } from '../../../nsp/security-program-posture';
import SampleInsight from 'components/insights/widgets/sample-insights-widget';
import {
  NumberOfVulnHostWoEdpWidgetComponent,
  VulnHostsWoEdpTableWidgetComponent,
} from '../../../insights/combined-insights/definitions/vuln-hosts-wo-edp/vuln-hosts-wo-edp';
import LatestOpenCasesWidget from '../../../case-management/widgets/latest-open-cases/latest-open-cases';
import { DataSourceListWidget } from './program/data-source-list';
import { HistogramWidget } from './histogram';
import { CbcAlertsWidget } from '../../../connections/connectors/carbon-black-cloud/widgets/cbc-alerts-widget/cbc-alerts-widget';
import { BarWidget } from './bar';
import { TopIncidentsAndRisksWidget } from '../../../connections/connectors/prisma-cloud/widgets/top-incidents-and-risks';
import { TopRiskyLocationsWidget } from './program/top-risky-locations';
import { QualysReportsWidget } from './qualys-reports';

export const WIDGET_COMPONENTS_MAP: {
  [key: string]: WidgetComponent<any, any>;
} = {
  bar: BarWidget,
  table: Table,
  doughnut: CountsDoughnut,
  donut: CountsDoughnut,
  histogram: HistogramWidget,
  line: Line,
  statistics: Statistics,
  [`custom-fortisiem-counts`]: CustomFortisiemCountsWidget,
  [`managed-asset-health`]: ManagedAssetHealthWidget,
  [`top-blocked-categories`]: TopBlockedCategories,
  [`nuspire-services`]: NuspireServicesWidget,
  [`service-value`]: ServiceValue,
  [`task-list`]: TaskList,
  [`service-risk`]: ServiceRisk,
  [`security-score-card`]: SecurityScoreCardWidget,
  [`snow-remediations-by-priority`]: ServiceNowRemediationsByPriorityWidget,
  [`industry-news`]: IndustryNews,
  [`technology-source-device-health`]: TechnologySourceDeviceWidget,
  [`technology-source-health`]: TechnologySourceWidget,
  [`threat-briefs`]: ThreatBriefs,
  [`monthly-event-summary`]: MonthlyEventSummaryWidget,
  meta: MetaWidget,
  [`count-pillar`]: CountPillar,
  [`security-program-posture`]: SecurityProgramPosture,
  [`number-of-vuln-hosts-wo-edp`]: SampleInsight,
  [`vuln-hosts-wo-edp`]: NumberOfVulnHostWoEdpWidgetComponent,
  [`vuln-hosts-wo-edp-table`]: VulnHostsWoEdpTableWidgetComponent,
  [`latest-open-cases`]: LatestOpenCasesWidget,
  [`data-source-list`]: DataSourceListWidget,
  [`carbon-black-alerts-table`]: CbcAlertsWidget,
  [`prisma-cloud-top-incidents-and-risks`]: TopIncidentsAndRisksWidget,
  [`top-risky-locations`]: TopRiskyLocationsWidget,
  [`qualys-reports`]: QualysReportsWidget,
};
