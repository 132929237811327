import { TableOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import { IDataExplorerContext, useDataExplorerContext } from 'components/data-explorer/data-explorer';
import { NuButton } from 'components/nuspire';
import { Doughnut } from 'components/nuspire/charts/doughnut-chart';
import InfiniteTable from 'components/nuspire/infinite-table';
import { Filter } from 'components/nuspire/nu-icon';
import { queryDataTypePath } from 'components/reporting-and-analysis/paths';
import baseTheme from 'components/theme';
import objectPath from 'object-path';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WidgetComponentProps } from '..';
import { FilterInput } from '../types';
import { FilterButtonRoot, FilterButtonValue, FilterButtonWrap } from './table';

export interface Values {
  count: number;
  title: string;
  value: string;
  backgroundColor?: string;
}

export interface DoughnutData {
  doughnutData: {
    title: string;
    values: Values[];
  };
}

export type DougnutConfig = null | {
  displayTotalCount?: boolean;
  filterInputs?: FilterInput[];
};

const backgroundColors = [
  baseTheme.color.nuspireBlue,
  baseTheme.color.slate,
  baseTheme.color.breeze,
  baseTheme.color.green,
  baseTheme.color.orange,
  baseTheme.color.indigo,
  baseTheme.color.softYellow,
  'rgb(215,0,217)',
  'rgb(69,162,255)',
  'rgb(81,130,255)',
  'rgb(43,6,160)',
  'rgb(175,142,239)',
  'rgb(94,227,234)',
  'rgb(79,89,98)',
  'rgb(149,158,161)',
  'rgb(198,202,203)',
  'rgb(81,130,155)',
  'rgb(200,250,150)',
  'rgb(150,50,90)',
  'rgb(100,150,150)',
  'rgb(150,90,150)',
  'rgb(60,60,255)',
  'rgb(255,90,150)',
  'rgb(100,130,255)',
  'rgb(70,80,90)',
  'rgb(53,150,150)',
  'rgb(255,130,255)',
  'rgb(100,150,100)',
  'rgb(100,100,255)',
  'rgb(0,0,0)',
  'rgb(200,100,45)',
  'rgb(34,200,67)',
  'rgb(78,100,170)',
  'rgb(110,42,240)',
  'rgb(200,40,200)',
];

export const DoughnutFlex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DoughnutWrap = styled.div`
  height: 325px;
`;

const DoughnutLegend = styled.div<{ height: number }>`
  height: ${(props) => props.height}%;
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const DoughnutLabel = styled(Typography.Text)`
  &:hover {
    cursor: default;
  }
`;

const DoughnutLegendSquare = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: 25px;
  height: 8px;
  display: inline-block;
`;

export default function CountsDoughnut(props: WidgetComponentProps<DougnutConfig, DoughnutData>) {
  const {
    data: { doughnutData },
    dataTypeKey,
    clientId,
    title,
    setSubAction,
    isReportWidget,
  } = props;
  const [hiddenValues, setHiddenValues] = useState<string[]>([]);
  const valuesWithCounts = (doughnutData?.values ?? []).filter((d) => d.count !== 0).sort((a, b) => b.count - a.count);
  const topFiveCounts = valuesWithCounts.slice(0, 5);
  const other = valuesWithCounts.slice(5).reduce((pre, curr) => (pre += curr.count), 0);

  if (other > 0) {
    topFiveCounts.push({ count: other, title: 'Other', value: '' });
  }
  const filteredTopFive = topFiveCounts.filter((d) => !hiddenValues.includes(d.title));

  let legendHeight = 20;
  if (topFiveCounts?.length <= 2) {
    legendHeight = 10;
  }

  useEffect(() => {
    if (setSubAction) {
      setSubAction(
        <NuButton shape="circle" icon={<TableOutlined onClick={() => setIsTableModalOpen(true)} />} type="text" />,
      );
    }
  }, []);

  const configuration = !props?.configuration ? {} : props.configuration;
  const { displayTotalCount = true, filterInputs } = configuration;
  const dataExplorerContext = useDataExplorerContext();
  const [isTableModalOpen, setIsTableModalOpen] = useState<boolean>(false);

  const handleClick = (_e, activeElements: any) => {
    const sliceIdx = activeElements[0]?.index;

    if (!dataTypeKey) {
      console.warn('No DataType Key to link to...');
      return;
    }

    if (sliceIdx === undefined) {
      console.warn('sliceIdx is undefined');
      return;
    }

    const slice = valuesWithCounts[sliceIdx];
    if (!slice) {
      console.warn(`slice ${sliceIdx} not found`);
    }

    if (dataExplorerContext) {
      return handleDataExplorerFilter({
        dataExplorerContext,
        slice,
        filterInputs,
      });
    }

    return handleEventOutsideDataExplorer({
      clientId,
      dataTypeKey,
      slice,
      filterInputs,
    });
  };

  return (
    <DoughnutFlex>
      <DoughnutWrap>
        <DoughnutLegend height={legendHeight}>
          {topFiveCounts?.map((item, index) => (
            <DoughnutLabel
              key={item.title}
              style={{ width: 150 }}
              ellipsis={{ tooltip: item.title }}
              delete={hiddenValues.includes(item.title)}
              onClick={() => {
                if (!hiddenValues.includes(item.title)) {
                  setHiddenValues([...hiddenValues, item.title]);
                } else {
                  setHiddenValues(hiddenValues.filter((v) => v !== item.title));
                }
              }}
            >
              <DoughnutLegendSquare
                backgroundColor={item?.backgroundColor ? item.backgroundColor : backgroundColors[index]}
              />{' '}
              {item.title}
            </DoughnutLabel>
          ))}
        </DoughnutLegend>
        <Doughnut
          doughnutHeight={100 - legendHeight}
          options={{
            cutout: 90,
            onClick: handleClick,
            plugins: {
              legend: {
                display: false,
              },
            },
            animation: isReportWidget ? { duration: 0 } : undefined,
          }}
          data={{
            labels: filteredTopFive.map((l) => l.title),
            datasets: [
              {
                data: filteredTopFive.map((d) => d.count),
                backgroundColor: filteredTopFive.map((val) => {
                  if (val.backgroundColor) {
                    return val.backgroundColor;
                  }

                  // Maintain correct color when hidding an element
                  const idx = topFiveCounts.findIndex((i) => i.title === val.title);
                  return backgroundColors[idx];
                }),
              },
            ],
          }}
          centerContent={
            displayTotalCount && (
              <Typography.Title level={4}>{`Total: ${valuesWithCounts.reduce(
                (acc, curr) => acc + curr.count,
                0,
              )}`}</Typography.Title>
            )
          }
        />
      </DoughnutWrap>
      <DoughnutTableModal
        open={isTableModalOpen}
        setIsOpen={setIsTableModalOpen}
        dataSource={valuesWithCounts}
        title={title}
        dataExplorerContext={dataExplorerContext}
        filterInputs={filterInputs}
        dataTypeKey={dataTypeKey}
        clientId={clientId}
      />
    </DoughnutFlex>
  );
}

/**
 * Handle Dataset selection inside data explorerContext;
 */
function handleDataExplorerFilter(args: {
  slice: object;
  dataExplorerContext: IDataExplorerContext;
  filterInputs?: FilterInput[];
}) {
  const {
    dataExplorerContext: { searchParamsInterface },
    slice,
    filterInputs,
  } = args;
  if (!searchParamsInterface) {
    return;
  }

  const changes = filterInputs?.reduce((acc: { name: string; value: any }[], filter) => {
    const dataIndex = filter.dataIndex;
    // find value from dot notation.
    const obj = {
      slice,
    };

    const pathValue = objectPath.get(obj, dataIndex);
    if (pathValue) {
      acc.push({
        name: filter.key,
        value: JSON.stringify(pathValue),
      });
    }

    return acc;
  }, []);

  if (changes?.length) {
    const { setParameters } = searchParamsInterface;

    setParameters(changes);
  }
}

/**
 * Handle slice selection outside of data explorer context.
 */
function handleEventOutsideDataExplorer(args: {
  clientId: string;
  slice: object;
  dataTypeKey: string;
  filterInputs?: FilterInput[];
}) {
  const { clientId, slice, dataTypeKey, filterInputs } = args;
  const searchParams = new URLSearchParams();

  filterInputs?.forEach((filter) => {
    const { key, dataIndex } = filter;
    const obj = {
      slice,
    };
    const pathValue = objectPath.get(obj, dataIndex);
    if (pathValue) {
      searchParams.set(key, JSON.stringify(pathValue));
    }
  });

  const path = `${window.location.origin}${queryDataTypePath({ clientId, dataType: dataTypeKey })}?${searchParams.toString()}`;
  // build up url

  // Open data explorer link in new tab
  window.open(path, '_blank');
}

type DoughnutTableModalProps = {
  title?: string;
  open: boolean;
  setIsOpen: Function;
  dataSource: Values[];
  dataExplorerContext: any;
  filterInputs?: FilterInput[];
  dataTypeKey?: string;
  clientId: string;
};

function DoughnutTableModal(props: DoughnutTableModalProps) {
  const { open, setIsOpen, dataSource, title, dataExplorerContext, filterInputs, dataTypeKey, clientId } = props;

  return (
    <Modal open={open} closeIcon={null} onCancel={() => setIsOpen(false)} title={title || 'All Counts'} footer={null}>
      <InfiniteTable
        dataSource={dataSource}
        columns={[
          {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (value: string, record: Values) => {
              return (
                <FilterButtonRoot className={`fob-root`}>
                  <FilterButtonValue>{value}</FilterButtonValue>
                  {dataTypeKey && (
                    <FilterButtonWrap>
                      <NuButton
                        icon={<Filter />}
                        size="small"
                        type="link"
                        onClick={() => {
                          if (dataExplorerContext) {
                            return handleDataExplorerFilter({
                              dataExplorerContext,
                              slice: record,
                              filterInputs,
                            });
                          }

                          return handleEventOutsideDataExplorer({
                            clientId,
                            dataTypeKey,
                            slice: record,
                            filterInputs,
                          });
                        }}
                      />
                    </FilterButtonWrap>
                  )}
                </FilterButtonRoot>
              );
            },
          },
          {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (value: number) => value?.toLocaleString(),
          },
        ]}
        rowKey="title"
      />
    </Modal>
  );
}
