export type PaginationDefaults = {
  currentPage: number;
  filter: string;
  pageSize: number;
  sortOrder: 'asc' | 'desc';
  calcSkip: (currPage: number, numItems: number) => number;
};

export type AppConfig = {
  apiBaseUrl: string; // The main API service
  graphPubSubUrl: string; // The main API service
  coreBaseUrl: string;
  environment: 'development' | 'local' | 'production';
  fortisiemBaseUrl: string;
  graphUrl: string;
  intercomAppId: string;
  isDev: boolean;
  isLocal: boolean;
  isProd: boolean;
  msspClientId: string;
  myNuspireDevs: string[];
  myNuspireVersion?: string;
  paginationDefaults: PaginationDefaults;
  sentry: {
    debug: boolean;
    dsn?: string;
    enabled: boolean;
    tracesSampleRate: number;
  };
  serviceNowBaseUrl: string;
  titleEnv: string;
};

const isDev: boolean = import.meta.env.DEV;
const isLocal: boolean = import.meta.env.VITE_USE_LOCAL_BACKEND === 'true';
const isProd: boolean = import.meta.env.PROD;

const getValueForEnv = <ValueType = string>(args: {
  dev: ValueType;
  local: ValueType;
  override?: ValueType;
  prod: ValueType;
}): ValueType => {
  if (args.override) return args.override;
  if (isLocal) return args.local;
  if (import.meta.env.DEV) return args.dev;

  return args.prod;
};

const apiBaseUrl = getValueForEnv({
  dev: 'https://graph.dev.mynuspire.io',
  local: 'http://localhost:8080',
  prod: 'https://graph.mynuspire.io',
});
const graphPubSubUrl = getValueForEnv({
  dev: 'wss://graph.dev.mynuspire.io/graph',
  local: 'ws://localhost:8080/graph',
  prod: 'wss://graph.mynuspire.io/graph',
});

export const config: AppConfig = {
  apiBaseUrl,
  graphPubSubUrl,
  coreBaseUrl: getValueForEnv({
    dev: 'https://api.dev.mynuspire.io',
    local: 'http://localhost:3337',
    prod: 'https://api.mynuspire.io',
  }),
  environment: getValueForEnv({
    dev: 'development',
    local: 'local',
    prod: 'production',
  }),
  fortisiemBaseUrl: getValueForEnv({
    dev: 'https://54.86.166.11',
    local: 'https://54.86.166.11',
    prod: 'https://esiem.nuspire.com',
  }),
  graphUrl: `${apiBaseUrl}/graph`,
  intercomAppId: import.meta.env.VITE_REACT_APP_INTERCOM_APP_ID,
  isDev,
  isLocal,
  isProd,
  msspClientId: getValueForEnv({
    dev: 'b5799d40-d6bc-4513-a1e0-342335be5c79', // BigAcme
    local: 'b5799d40-d6bc-4513-a1e0-342335be5c79', // BigAcme
    prod: '6d260b5f-cf9f-46ee-9c0c-4b02a4923937', // Nuspire MSSP
  }),
  myNuspireDevs: [
    'andrew.roe@nuspire.com',
    'derek.haefner@nuspire.com',
    'jeremy.couser@nuspire.com',
    'mark.belles@nuspire.com',
    'terry.zhao@nuspire.com',
    'tyler.elton@nuspire.com',
    'tyler.vollick@nuspire.com',
    'zachary.drake@nuspire.com',
    'marcy.elder@nuspire.com',
  ].map((email) => email.toLowerCase()),
  myNuspireVersion: import.meta.env.VITE_GITHUB_SHA,
  paginationDefaults: {
    currentPage: 1,
    filter: `any`,
    pageSize: 20,
    sortOrder: `desc`,
    calcSkip(currPage: number, numItems: number): number {
      return numItems * (currPage - 1);
    },
  },
  sentry: {
    debug: getValueForEnv({ dev: true, local: false, prod: false }),
    dsn: getValueForEnv({
      dev: 'https://4a5febaf2ef545e5aa6ff93606d70b25@o4504765198172160.ingest.us.sentry.io/4504770188607488',
      local: undefined,
      prod: 'https://4a5febaf2ef545e5aa6ff93606d70b25@o4504765198172160.ingest.us.sentry.io/4504770188607488',
    }),
    enabled: getValueForEnv({ dev: true, local: false, prod: true }),
    tracesSampleRate: getValueForEnv({ dev: 0.3, local: 0.0, prod: 0.6 }),
  },
  serviceNowBaseUrl: getValueForEnv({
    dev: 'https://nuspiredev.service-now.com',
    local: 'https://nuspiredev.service-now.com',
    prod: 'https://nuspire.service-now.com',
  }),
  titleEnv: getValueForEnv({ dev: '(DEV)', local: '(LOCAL)', prod: '' }),
};
