/* eslint-disable @typescript-eslint/no-empty-function */
import { gql, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAuthContext, RefreshAuthContextArgs } from 'types';
import client from 'utils/graphql/client';
import { REDIRECT_TO_KEY } from '../localstorage';
import { isCompanyEmail } from '../utils/users';
import { isMyNuspireDev } from './feature-flags';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      clientId
      customTags
      email
      firstName
      isSuperUser
      lastName
      mobilePhoneNumber
      mobilePhoneNumberCountry
      persona
      phoneNumber
      phoneNumberCountry
      phoneNumberExt
      settings
      serviceNowUserId
      title
      authenticationType
      enabledFeatures
      acceptedLicenseAgreement
      client {
        apiKeyId
        id
        createdAt
        name
        industry {
          id
          name
        }
        isMultiTenancyEnabled
        path
        clientIdentifiers {
          type
          value
        }
        type
      }
      managedClientIds
      managedClients {
        id
        name
      }
      favoriteDashboard {
        id
        shortId
      }
      caseManagementFilters {
        cases {
          assignmentGroups
          dateField
          includeChildren
          priorities
          resolutionCodes
          search
          states
          time
          user
        }
        incidents {
          assignmentGroups
          dateField
          closeCodes
          includeChildren
          priorities
          search
          states
          time
          user
        }
      }
    }
  }
`;

export const AuthContext = createContext<IAuthContext>({
  error: undefined,
  isMyNuspireDev: false,
  isCompanyEmail: false,
  loading: false,
  logout: () => {},
  refresh: () => {},
  user: undefined,
});

export function CurrentUserProvider(props: { children: ReactNode }) {
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_QUERY);
  const user = data?.currentUser;

  const navigate = useNavigate();

  const redirectTo = (to: string) => {
    // In case user is viewing something other than the top of the page.
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(to);
  };

  // useEffect(() => {
  //   if (error && !user) {
  //     throw new Error('An error occurred while attempting to load your user information.');
  //   }
  // }, [error, user]);

  useEffect(() => {
    if (!user) return;

    Sentry.setUser({
      display: user?.email,
      email: user?.email,
      id: user?.id,
      username: user?.email,
      ip_address: '{{auto}}',
    });

    if (import.meta.env.PROD) {
      mixpanel.identify(user?.id);
      mixpanel.people.set({
        $email: user?.email,
        userId: user?.id,
        clientId: user?.clientId,
        nuspireCustomer: user?.serviceNowUserId !== null,
        firstName: user?.firstName,
        lastName: user?.lastName,
        clientName: user?.client?.name,
        persona: user?.persona,
      });
    }
  }, [user]);

  const context = useMemo(
    () => ({
      error,
      loading,
      user,
      isMyNuspireDev: isMyNuspireDev({ user }),
      isCompanyEmail: isCompanyEmail({ email: user?.email ?? '' }),
      logout: () => {
        localStorage.removeItem(REDIRECT_TO_KEY);
        client.clearStore();
        refetch();
      },
      refresh: (args: RefreshAuthContextArgs = {}) => {
        const { redirectToFn } = args;

        refetch().then((result) => {
          const newClientId: string | undefined = result?.data?.currentUser?.clientId;

          if (redirectToFn && newClientId) {
            const to = redirectToFn(newClientId);
            redirectTo(to);
          }
        });
      },
    }),
    [error, loading, user],
  );

  const { children } = props;
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);
export const useCurrentUser = useAuthContext;
