import { gql, useQuery } from '@apollo/client';
import { Typography } from 'antd';
import { useClientContext } from 'components/client-context-provider';
import { Link, NuPaper } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import { User as UserIcon } from 'components/nuspire/nu-icon';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import baseTheme from 'components/theme';
import styled from 'styled-components';

const USER_GROUPS = gql`
  query UserGroups($clientId: String) {
    userGroups(clientId: $clientId) {
      id
      createdAt
      description
      isHidden
      isSystemControlled
      name
      updatedAt
      members(clientId: $clientId) {
        id
      }
    }
  }
`;

type Maybe<T> = T | null;

export interface User {
  id: string;
}

export interface UserGroup {
  id: string;
  clientId: Maybe<string>;
  createdAt: Maybe<string>;
  description: Maybe<string>;
  isHidden: Maybe<boolean>;
  isSystemControlled: Maybe<boolean>;
  name: string;
  updatedAt: Maybe<string>;
  members: Maybe<User[]>;
}

const UgliRoot = styled.div`
  margin-bottom: 24px;
`;
const UgliContent = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`;
const UgliName = styled.div`
  padding-right: 16px;
  flex: 2;

  .ugli-paper:hover & .ant-typography {
    transition: color ease 0.25s;
    color: ${baseTheme.color.primary};
  }
`;
const UgliDesc = styled.div`
  flex: 2;
`;
const UgliMembersCount = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

function getMembersCount(users: Maybe<User[]>) {
  if (!users || !users.length) {
    return '0 members';
  }

  const count = users.length;

  return `${count} member${count !== 1 ? 's' : ''}`;
}

function UserGroupListItem(props: {
  userGroup: UserGroup;
  userGroupsBaseUrl?: string; // /admin/clients/.../user-groups
}) {
  const {
    userGroup: { id, name, description, members },
    userGroupsBaseUrl,
  } = props;
  const { clientId } = useClientContext();
  const membersCount = getMembersCount(members);
  const baseUrl = userGroupsBaseUrl ? userGroupsBaseUrl.replace(/\/$/, '') : `/${clientId}/users/groups`;

  return (
    <UgliRoot>
      <Link to={`${baseUrl}/${id}`}>
        <NuPaper hoverable classes={{ root: 'ugli-paper' }} level={2}>
          <UgliContent>
            <UgliName>
              <Typography.Title
                level={4}
                style={{
                  marginBottom: 0,
                }}
              >
                {name}
              </Typography.Title>
            </UgliName>

            <UgliDesc>
              <Typography.Text type="secondary">{description}</Typography.Text>
            </UgliDesc>

            <UgliMembersCount>
              <UserIcon style={{ marginRight: '8px' }} />
              {membersCount}
            </UgliMembersCount>
          </UgliContent>
        </NuPaper>
      </Link>
    </UgliRoot>
  );
}

function UserGroupList(props: {
  userGroups: UserGroup[];
  userGroupsBaseUrl?: string; // /admin/clients/.../user-groups
}) {
  const { userGroups, userGroupsBaseUrl } = props;

  return (
    <>
      {userGroups.map((ug) => (
        <UserGroupListItem key={ug.id} userGroup={ug} userGroupsBaseUrl={userGroupsBaseUrl} />
      ))}
    </>
  );
}

function UserGroups(props: {
  clientId: string;
  userGroupsBaseUrl?: string; // /admin/clients/.../user-groups
}) {
  const { clientId, userGroupsBaseUrl } = props;
  const { data, loading } = useQuery(USER_GROUPS, { variables: { clientId } });
  const userGroups: UserGroup[] | null = data?.userGroups ?? null;

  if (userGroups?.length) {
    return <UserGroupList userGroups={userGroups} userGroupsBaseUrl={userGroupsBaseUrl} />;
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin tip="Loading User Groups">
          <div className="content" />
        </Spin>
      </SpinContainer>
    );
  }

  return <EmptyState>No User Groups were found.</EmptyState>;
}

export default UserGroups;
