import { Form, Input, Modal, Select, Table, Tabs, Typography, message } from 'antd';
import { NuButton } from 'components/nuspire';
import EmptyState from 'components/nuspire/nu-empty-state';
import { WidgetComponentProps } from '..';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { downloadUrlAsFile } from 'utils/download-url-as-file';
import { DownloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { client } from 'utils/graphql';
import { ConnectionInput } from 'components/cyberx/actions/action-form/action-form-field/inputs';

dayjs.extend(relativeTime);

const QUALYS_REPORTS_QUERY = gql`
  query QualysReports($clientId: String!, $connectionId: String, $reportId: String) {
    qualysReports(clientId: $clientId, connectionId: $connectionId, reportId: $reportId) {
      connectionId
      items {
        id
        status
        launchDatetime
        outputFormat
        size
        title
        userLogin
      }
    }
  }
`;

const DOWNLOAD_QUALYS_REPORT_MUTATION = gql`
  mutation DownloadQualysReport($clientId: String!, $connectionId: String!, $reportId: String!) {
    downloadQualysReport(clientId: $clientId, connectionId: $connectionId, reportId: $reportId) {
      filename
      downloadUrl
    }
  }
`;

export function useDownloadQualysReport(args: { clientId: string; connectionId: string }) {
  const { clientId, connectionId } = args;
  const [downloadReport, { loading }] = useMutation(DOWNLOAD_QUALYS_REPORT_MUTATION);

  const handleDownload = async (reportId: string) => {
    if (loading) {
      console.warn('Report is already being downloaded.');
      return;
    }

    try {
      const { data } = await downloadReport({
        variables: {
          clientId,
          connectionId,
          reportId,
        },
      });

      if (!data) {
        throw new Error(`Failed to download data`);
      }
      const url = data.downloadQualysReport.downloadUrl;
      const filename = data.downloadQualysReport.filename;

      downloadUrlAsFile({
        url,
        fileName: filename,
        newTab: false,
      });

      return true; // success
    } catch (err) {
      message.error('Failed to download data');

      return false;
    }
  };

  return { handleDownload, loading };
}

function DownloadButton(props: { clientId: string; connectionId: string; reportId: string }) {
  const { reportId } = props;
  const { handleDownload, loading } = useDownloadQualysReport(props);

  return (
    <NuButton
      onClick={() => handleDownload(reportId)}
      type="text"
      icon={<DownloadOutlined />}
      loading={loading}
      disabled={loading}
      children="Download"
    />
  );
}

const QUALYS_REPORT_TEMPLATES_QUERY = gql`
  query QualysReportsTemplates($clientId: String!, $connectionId: String) {
    qualysReportTemplates(clientId: $clientId, connectionId: $connectionId) {
      connectionId
      items {
        id
        title
        type
        lastUpdate
        templateType
        user {
          login
        }
      }
    }
  }
`;

export function QualysReportTemplatesTable(props: { clientId: string; connectionId?: string }) {
  const { clientId, connectionId } = props;
  const { data, loading } = useQuery(QUALYS_REPORT_TEMPLATES_QUERY, { variables: { clientId, connectionId } });
  const rowData = data?.qualysReportTemplates?.items;

  if (rowData) {
    const connectionIdUsed = data.qualysReportTemplates.connectionId;

    return (
      <>
        <Table
          dataSource={rowData}
          pagination={false}
          columns={[
            {
              key: 'title',
              title: 'Title',
              dataIndex: 'title',
              render: (val) => val ?? '--',
            },
            {
              key: 'user',
              title: 'User',
              dataIndex: ['user', 'login'],
            },
            {
              key: 'updated',
              title: 'Last Updated',
              dataIndex: 'lastUpdate',
              render: (val) => new Date(val).toLocaleDateString(),
            },
            {
              key: 'run-and-download',
              dataIndex: 'id',
              render: (id, record) => (
                <RunAndDownloadTemplateBtn
                  clientId={clientId}
                  connectionId={connectionIdUsed}
                  templateId={id}
                  templateTitle={record.title}
                  templateType={record.templateType}
                />
              ),
            },
          ]}
        />
      </>
    );
  }

  if (loading) {
    return (
      <SpinContainer about="Fetching Reports">
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>Failed to fetch report templates. Make sure you have a healthy Qualys Connection.</EmptyState>;
}

const LAUNCH_QUALYS_REPORT_MUTATION = gql`
  mutation LaunchQualysReport(
    $clientId: String!
    $connectionId: String!
    $templateId: String!
    $tagIds: [Int!]
    $title: String
    $outputFormat: QualysReportOutputFormat
  ) {
    launchQualysReport(
      clientId: $clientId
      connectionId: $connectionId
      templateId: $templateId
      tagIds: $tagIds
      title: $title
      outputFormat: $outputFormat
    ) {
      reportId
      apiUsage {
        rateLimitRemaining
      }
    }
  }
`;

export type QualysReportOutputFormat = 'pdf' | 'csv' | 'html' | 'docx' | 'mht' | 'xml';

const TEMPLATE_TYPE_OUTPUT_MAP: {
  [key: string]: QualysReportOutputFormat[];
} = {
  Scan: ['pdf', 'html', 'mht', 'xml', 'csv', 'docx'],
  Map: ['pdf', 'html', 'mht', 'xml', 'csv'],
  Remediation: ['pdf', 'html', 'mht', 'csv'],
  Compliance: ['pdf', 'html', 'mht'],
  Patch: ['pdf', 'csv', 'xml'],
  All: ['pdf', 'csv', 'html', 'docx', 'mht', 'xml'],
};

function getOutputSelectOptions(props: { templateType: string }) {
  const { templateType } = props;
  const supportedOutputTypes = TEMPLATE_TYPE_OUTPUT_MAP[templateType] ?? TEMPLATE_TYPE_OUTPUT_MAP.All;

  const OUTPUT_SELECT_OPTIONS: {
    value: QualysReportOutputFormat;
    label: string;
  }[] = [
    {
      value: 'pdf',
      label: 'Portable Document Format (PDF)',
    },
    {
      value: 'csv',
      label: 'Comma-Separated Value (CSV)',
    },
    {
      value: 'xml',
      label: 'Extensible Markup Language (XML)',
    },
    {
      value: 'html',
      label: 'HTML pages',
    },
    {
      value: 'docx',
      label: 'Microsoft Document (DOCX)',
    },
    {
      value: 'mht',
      label: 'Web Archive (MHT) -- Internet Explorer for Windows only',
    },
  ];

  return OUTPUT_SELECT_OPTIONS.filter((e) => supportedOutputTypes.includes(e.value));
}

const QUALYS_TAGS_QUERY = gql`
  query QualysTags($clientId: String!, $connectionId: String) {
    qualysTags(clientId: $clientId, connectionId: $connectionId) {
      items {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

export function LaunchReportForm(props: {
  clientId: string;
  connectionId: string;
  templateId: string;
  templateTitle: string;
  onLaunched: (args: { reportId: string }) => Promise<void>;
  templateType: string;
}) {
  const { onLaunched, templateTitle, templateId, connectionId, clientId, templateType } = props;

  const now = new Date();
  const formattedDate =
    now.getFullYear().toString() + String(now.getMonth() + 1).padStart(2, '0') + String(now.getDate()).padStart(2, '0');

  const [form] = Form.useForm();

  const [launchReport, { loading }] = useMutation(LAUNCH_QUALYS_REPORT_MUTATION);

  const { data: tagsData, loading: loadingTags } = useQuery(QUALYS_TAGS_QUERY, {
    variables: { connectionId, clientId },
  });

  const tagOptions = tagsData?.qualysTags?.items?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const outputSelectOptions = getOutputSelectOptions({ templateType });

  return (
    <Form
      form={form}
      onFinish={async (values) => {
        const { title, outputFormat, tagIds } = values;

        const { data, errors } = await launchReport({
          variables: {
            clientId,
            connectionId,
            templateId,

            // from inputs.
            tagIds,
            title,
            outputFormat,
          },
        });

        const reportId = data?.launchQualysReport?.reportId;
        if (!reportId) {
          console.error('Failed to launch report');
          console.error(errors);
          message.error('Failed to launch report.');
          return;
        }

        onLaunched({ reportId });
      }}
      initialValues={{
        title: `${templateTitle} - ${formattedDate}`,
        outputFormat: 'pdf',
      }}
    >
      <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title Required' }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Report Format"
        name="outputFormat"
        rules={[{ required: true, message: 'Output Format Required' }]}
      >
        <Select options={outputSelectOptions} />
      </Form.Item>

      <Form.Item label="Tags" name="tagIds">
        <Select loading={loadingTags} options={tagOptions} mode="multiple" />
      </Form.Item>

      <Form.Item>
        <NuButton type="primary" htmlType="submit" disabled={loading} loading={loading}>
          Submit
        </NuButton>
      </Form.Item>
    </Form>
  );
}

export function RunAndDownloadTemplateBtn(props: {
  clientId: string;
  connectionId: string;
  templateId: string;
  templateTitle: string;
  templateType: string;
}) {
  const { clientId, connectionId, templateId, templateTitle, templateType } = props;
  const { handleDownload, loading: downloading } = useDownloadQualysReport({ clientId, connectionId });
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  // will need to poll for report once it has been launched.
  const [fetchQualysReports] = useLazyQuery(QUALYS_REPORTS_QUERY);

  const pollRunningReport = async ({ reportId, retries }: { reportId: string; retries?: number }) => {
    const pollResults = await fetchQualysReports({
      variables: {
        clientId,
        connectionId,
        reportId,
      },
    });

    const report = pollResults.data?.qualysReports?.items?.[0];
    if (!report) {
      if (retries && retries > 5) {
        message.error('Failed to poll running report');
        return false;
      }

      // appears to be a small lag sometimes before new report is returned in list.
      // wait 2 seconds and try again.
      console.warn('Failed to return report. waiting 2 seconds then trying again.');
      await new Promise<void>((res) => {
        setTimeout(() => {
          res();
        }, 2000);
      });

      return pollRunningReport({
        reportId,
        retries: retries ? retries + 1 : 1,
      });
    }

    // Report is finished. stop polling.
    if (report.status === 'Finished') return true;

    if (report.status === 'Running') {
      // Report is running. Poll again in 5 seconds to check for completion.
      await new Promise<void>((res) => {
        setTimeout(() => {
          res();
        }, 5000);
      });

      return pollRunningReport({ reportId });
    }

    console.error('Failed to poll running report');
    if (pollResults.error) {
      console.error(pollResults.error);
    }
    return false;
  };

  const handleDownloadButtonClick = () => {
    setModalIsOpen(true);
  };

  const handleLaunchedReport = async (args: { reportId: string }) => {
    const { reportId } = args;
    setIsPolling(true);

    // Poll report status and download when Finished.
    const finished = await pollRunningReport({ reportId });

    setIsPolling(false);

    client.refetchQueries({
      include: ['QualysReports'],
    });

    if (finished) {
      // Report is finished and ready to start download.
      const downloadSuccess = await handleDownload(reportId);

      if (downloadSuccess) {
        setModalIsOpen(false);
      }
    }
  };

  const runningReportTip = `    
    This may take a few minutes to complete. You may close this modal and the report will still run in the background and download to your browser as long as you stay on this page. Otherwise, you can always come back to the "Reports" tab to download reports that have already been run. 
  `;

  return (
    <>
      <NuButton
        onClick={handleDownloadButtonClick}
        type="text"
        icon={<DownloadOutlined />}
        disabled={modalIsOpen || isPolling || downloading}
        children={isPolling || downloading ? 'Preparing Download' : 'Run & Download'}
      />

      {modalIsOpen && (
        <Modal
          open
          title="Launch Report"
          footer={false} // don't show footer by default.
          onCancel={() => setModalIsOpen(false)}
        >
          {!isPolling && !downloading && (
            <LaunchReportForm
              clientId={clientId}
              connectionId={connectionId}
              templateId={templateId}
              onLaunched={handleLaunchedReport}
              templateTitle={templateTitle}
              templateType={templateType}
            />
          )}
          {(isPolling || downloading) && (
            <SpinContainer>
              <Spin tip={isPolling ? 'Running Report' : 'Preparing Download'}>
                <div className="content" />
              </Spin>
            </SpinContainer>
          )}

          {isPolling && (
            <div style={{ marginTop: '24px', textAlign: 'center' }}>
              <Typography.Text>{runningReportTip}</Typography.Text>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

export function QualysReportsTable(props: {
  clientId: string;
  connectionId: string | null; // this is managed via state by parent component.
}) {
  const { clientId, connectionId } = props;

  const { data, loading, error } = useQuery(QUALYS_REPORTS_QUERY, {
    variables: {
      clientId,
      connectionId,
    },
  });

  const rowData = data?.qualysReports?.items;

  if (rowData) {
    const connectionIdUsed = data?.qualysReports.connectionId;
    return (
      <>
        <Table
          dataSource={rowData}
          pagination={false}
          columns={[
            {
              key: 'title',
              title: 'Title',
              dataIndex: 'title',
              render: (value) => value ?? '--',
            },
            {
              key: 'status',
              title: 'Status',
              dataIndex: 'status',
              render: (value) => value ?? '--',
            },
            {
              key: 'launched',
              title: 'Launched',
              dataIndex: 'launchDatetime',
              render: (v) => dayjs(v).fromNow(),
            },
            {
              key: 'size',
              title: 'Size',
              dataIndex: 'size',
            },
            {
              key: 'user',
              title: 'User',
              dataIndex: 'userLogin',
            },
            {
              key: 'download',
              dataIndex: 'id',
              render: (reportId) => {
                return <DownloadButton reportId={reportId} clientId={clientId} connectionId={connectionIdUsed} />;
              },
            },
          ]}
        />
      </>
    );
  }

  if (loading) {
    return (
      <SpinContainer about="Fetching Reports">
        <Spin />
      </SpinContainer>
    );
  }

  return <EmptyState>Failed to fetch reports. Make sure you have a healthy Qualys Connection.</EmptyState>;
}

export function QualysReportsWidget(props: WidgetComponentProps<null, null>) {
  const { clientId } = props;
  const [connection, setConnection] = useState<{ id: string; name: string } | null>(null);

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <div style={{ width: '300px' }}>
            <ConnectionInput
              value={connection}
              clientId={clientId}
              name="connection"
              field={{
                key: 'connection',
                type: 'string',
                label: 'Connection',
                parameters: {
                  connectorSlug: 'qualys',
                },
              }}
              onChange={(val) => setConnection(val)}
            />
          </div>
        }
        items={[
          {
            key: 'report-templates',
            label: 'Templates',
            children: <QualysReportTemplatesTable clientId={clientId} connectionId={connection?.id} />,
          },
          {
            key: 'reports',
            label: 'Reports',
            children: <QualysReportsTable clientId={clientId} connectionId={connection?.id ?? null} />,
          },
        ]}
      />
    </>
  );
}

export default QualysReportsWidget;
