import { Select, Tooltip } from 'antd';
import { IDataExplorerContext, useDataExplorerContext } from 'components/data-explorer/data-explorer';
import { Link, NuButton } from 'components/nuspire';
import { InsightsIcon } from 'components/nuspire/nu-icon';
import { queryDataTypePath } from 'components/reporting-and-analysis/paths';
import baseTheme from 'components/theme';
import dayjs from 'dayjs';
import objectPath from 'object-path';
import React, { useEffect, useState } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { WidgetComponentProps } from '..';
import { FilterInput } from '../types';

export interface Values {
  count: number;
  title: string;
  value: string;
  backgroundColor?: string;
}

export interface LineData {
  labels?: string[];
  datasets: { label: string; data: { x: string; y: number }[] }[];
}

export interface LineFilters {
  key: string;
  defaultValue: string | number;
  options: { key: string | number; value: string | number; label: string }[];
}

interface LineConfig {
  linkText?: string;
  link?: string;
  options?: {
    [key: string]: any; // temp
  };
  filterInputs?: FilterInput[];
  filters?: LineFilters[];
  disableScale?: boolean;
}

export const backgroundColors = [
  baseTheme.color.nuspireBlue,
  baseTheme.color.slate,
  baseTheme.color.breeze,
  baseTheme.color.green,
  baseTheme.color.orange,
  baseTheme.color.indigo,
  baseTheme.color.softYellow,
  'rgb(215,0,217)',
  'rgb(69,162,255)',
  'rgb(81,130,255)',
  'rgb(43,6,160)',
  'rgb(175,142,239)',
  'rgb(94,227,234)',
  'rgb(79,89,98)',
  'rgb(149,158,161)',
  'rgb(198,202,203)',
  'rgb(81,130,155)',
  'rgb(200,250,150)',
  'rgb(150,50,90)',
  'rgb(100,150,150)',
  'rgb(150,90,150)',
  'rgb(60,60,255)',
  'rgb(255,90,150)',
  'rgb(100,130,255)',
  'rgb(70,80,90)',
  'rgb(53,150,150)',
  'rgb(255,130,255)',
  'rgb(100,150,100)',
  'rgb(100,100,255)',
  'rgb(0,0,0)',
  'rgb(200,100,45)',
  'rgb(34,200,67)',
  'rgb(78,100,170)',
  'rgb(110,42,240)',
  'rgb(200,40,200)',
];

export default function Line(props: WidgetComponentProps<LineConfig, LineData>) {
  const {
    data: { labels, datasets: intialDatasets },
    setSubAction,
    configuration,
    clientId,
    dataTypeKey,
    onFetch,
    isReportWidget,
  } = props;
  const navigate = useNavigate();
  const [datasets, setDatasets] = useState(intialDatasets ?? []);

  const { linkText, link, options = {}, filterInputs, filters, disableScale = false } = configuration ?? {};

  useEffect(() => {
    if (setSubAction && link && linkText) {
      const subAction = dataTypeKey ? (
        <Tooltip title={linkText}>
          <NuButton
            icon={<InsightsIcon />}
            size="small"
            type="link"
            onClick={() => {
              navigate(`/${clientId}${link}`);
            }}
          />
        </Tooltip>
      ) : (
        <Link to={`/${clientId}${link}`} style={{ marginRight: '1rem' }}>
          {linkText}
        </Link>
      );
      setSubAction(subAction);
    }
  }, []);

  // check to see if widget is within dataexplorer context;
  const dataExplorerContext = useDataExplorerContext();

  const handleClick = (_e, activeElements: any) => {
    console.log({ _e, activeElements });

    /**
     * It is possible that two points from two different series exist on the same x,y coord.
     * Therefore, a click they both appear in "activeElements".
     * Currently, we are just taking the first one in order to link into data explorer...
     */
    const activeElement = activeElements[0];
    const datasetIdx = activeElements[0]?.datasetIndex;
    const rawX = activeElement?.element?.$context?.raw?.x;
    const xDate = Boolean(rawX?.match(/\d{4}-\d{2}-\d{2}/)) ? rawX : null;

    if (!dataTypeKey) {
      console.warn('No DataType Key to link to...');
      return;
    }

    if (datasetIdx === undefined) {
      console.warn('No dataset selected'); // delete after testing.
      return;
    }

    const dataset = datasets[datasetIdx];
    if (!dataset) {
      console.warn(`No dataset at idx: ${datasetIdx}.`);
      return;
    }

    if (dataExplorerContext) {
      return handleDataExplorerFilter({
        dataset,
        filterInputs,
        dataExplorerContext,
        xDate,
      });
    } else {
      return handleEventOutsideDataExplorer({
        clientId,
        dataTypeKey,
        dataset,
        filterInputs,
        xDate,
      });
    }
  };

  const hasFilters = filters && filters.length > 0;

  return (
    <>
      {hasFilters ? (
        <WidgetFilters filters={filters} onFetch={onFetch} setDatasets={setDatasets} dataKey="datasets" />
      ) : null}
      <LineChart
        style={{
          maxHeight: '500px',
        }}
        options={{
          maintainAspectRatio: false,
          ...options,
          animation: isReportWidget ? { duration: 0 } : undefined,
          onClick: handleClick,
          // setting this scale on incidents trend stops the data from rendering
          scales:
            options.scales ??
            (!disableScale
              ? {
                  x: {
                    type: 'time',
                  },
                }
              : undefined),
        }}
        data={{
          labels,
          datasets: datasets.map((d, i) => {
            /*
            we are saving dates as UTC but chartjs is converting them to local time
            so our trend charts are showing all data a day behind
            https://github.com/chartjs/Chart.js/issues/4334
            grab the timezone offset and add it to each date
          */

            return {
              ...d,
              backgroundColor: backgroundColors[i],
              borderColor: backgroundColors[i],
            };
          }),
        }}
      />
    </>
  );
}

/**
 * Handle Dataset selection inside data explorerContext;
 */
function handleDataExplorerFilter(args: {
  dataset: object;
  dataExplorerContext: IDataExplorerContext;
  filterInputs?: FilterInput[];
  xDate?: string;
}) {
  const {
    dataExplorerContext: { searchParamsInterface },
    dataset,
    filterInputs,
    xDate,
  } = args;
  if (!searchParamsInterface) {
    return;
  }

  let changes = filterInputs?.reduce((acc: { name: string; value: any }[], filter) => {
    const dataIndex = filter.dataIndex;
    // find value from dot notation.
    const obj = {
      dataset,
    };

    const pathValue = objectPath.get(obj, dataIndex);
    if (pathValue) {
      acc.push({
        name: filter.key,
        value: JSON.stringify(pathValue),
      });
    }

    return acc;
  }, []);

  if (xDate) {
    // Grab the current utc timestamp and add 24 hours to get endOfDay
    const startOfDay = dayjs(xDate).toISOString();
    const endOfDay = dayjs(xDate).add(24, 'hour').toISOString();

    console.log({ startOfDay, endOfDay });

    // searchParams.set('time', `from:${startOfDay},to:${endOfDay}`);
    const timeChange = {
      name: 'time',
      value: `from:${startOfDay},to:${endOfDay}`,
    };

    if (changes) {
      changes.push(timeChange);
    } else {
      changes = [timeChange];
    }
  }

  if (changes?.length) {
    const { setParameters } = searchParamsInterface;
    setParameters(changes);
  }
}

/**
 * Handle dataset selection outside of data explorer context.
 */
function handleEventOutsideDataExplorer(args: {
  clientId: string;
  dataset: object;
  dataTypeKey: string;
  filterInputs?: FilterInput[];
  xDate?: string;
}) {
  const { clientId, dataset, dataTypeKey, filterInputs, xDate } = args;

  const searchParams = new URLSearchParams();

  /**
   * Add appropriate filters for Data Explorer
   */
  filterInputs?.forEach((filter) => {
    const { key, dataIndex } = filter;
    const obj = {
      dataset,
    };
    const pathValue = objectPath.get(obj, dataIndex);
    if (pathValue) {
      searchParams.set(key, JSON.stringify(pathValue));
    }
  });

  /**
   * If x coord is a date, add time search params for data explorer to pick up on.
   */
  if (xDate) {
    // Grab the current utc timestamp and add 24 hours to get endOfDay
    const startOfDay = dayjs(xDate).toISOString();
    const endOfDay = dayjs(xDate).add(24, 'hour').toISOString();

    console.log({ startOfDay, endOfDay });

    searchParams.set('time', `from:${startOfDay},to:${endOfDay}`);
  }

  const path = `${window.location.origin}${queryDataTypePath({ clientId, dataType: dataTypeKey })}?${searchParams.toString()}`;
  // build up url

  // Open data explorer link in new tab
  window.open(path, '_blank');
}

export function WidgetFilters(args: {
  filters: LineFilters[];
  onFetch?: Function;
  setDatasets: Function;
  dataKey?: string; // grab a nested item from the results
  style?: React.CSSProperties;
}) {
  const { filters, onFetch, setDatasets, dataKey, style } = args;
  const [filterValues, setFilterValues] = useState(
    filters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key]: curr.defaultValue,
      }),
      {},
    ),
  );
  return (
    <div style={style}>
      {filters.map((filter) => (
        <Select
          style={{ width: '150px' }}
          key={filter.key}
          defaultValue={filter.defaultValue}
          onChange={async (value) => {
            const newFilterValues = { ...filterValues, [filter.key]: value };
            if (onFetch) {
              const result = await onFetch({ variables: newFilterValues });

              setDatasets(dataKey ? result[`${dataKey}`] : result);
            }

            setFilterValues(newFilterValues);
          }}
        >
          {filter.options.map((option) => (
            <Select.Option key={option.key} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      ))}
    </div>
  );
}
