import { QuestionCircleOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Dropdown, Input, InputRef, Menu, message, Tooltip } from 'antd';
import {
  ClearFiltersButton,
  ComplexSearchRoot,
  ComplexSearchSelector,
  ComplexSearchSelectorOverflow,
} from 'components/complex-search';
import { ComplexSearchFilterButton, DataType, SEARCH, SearchInputRoot } from 'components/data-explorer/data-explorer';
import { FilterDropdownRoot, FilterInputListItem, FilterListRoot } from 'components/data-explorer/filter-dropdown';
import { FilterTags } from 'components/data-explorer/filter-tags';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ActionFormFieldType,
  CaseManagementFilterOptionsQuery,
  CaseManagementFilterOptionsQueryVariables,
  CaseManagementType,
  SubcategoriesByCategoryQuery,
  SubcategoriesByCategoryQueryVariables,
} from 'types/graph-codegen/graph-types';
import useSearchParams from 'utils/react-hooks/useSearchParams';
import { capitalize } from 'utils/strings';
import { getDirectlyAssociatedTooltipText, priorities } from './case-management-table';
import { SUBCATEGORIES_BY_CATEGORY_QUERY } from './open-case';

export const CASE_MANAGEMENT_FILTER_OPTIONS = gql`
  query CaseManagementFilterOptions($type: CaseManagementType!) {
    caseManagementAssignmentGroups {
      id
      name
    }
    getIncidentResolutionCodes {
      id
      name
    }
    getCaseResolutionCodes {
      id
      name
    }
    caseManagementCategories(type: $type) {
      id
      name
    }
  }
`;

type FilterOption = {
  id: string;
  name: string;
};

const FiltersRoot = styled.div`
  flex: 1;
  display: flex;
`;

export type FilterFormValues = {
  includeChildren?: boolean;
  user?: boolean;
  priorities?: string[];
  search?: string;
  states?: string[];
};

export type CaseManagementFiltersProps = {
  isMultiTenancyEnabled?: boolean;
  states: string[];
  statesLoading: boolean;
  type: 'cases' | 'incidents';
};

export type FilterFormProps = {
  dataType?: DataType;
  inputOrder: string[]; // array of input keys that determines the order of (or if we show) the input
};

const FilterForm: FC<FilterFormProps> = (props) => {
  const { dataType, inputOrder } = props;
  const { setParameter } = useSearchParams();

  if (!dataType?.filterInputs) {
    return null;
  }

  const inputs = dataType.filterInputs
    .filter((input) => inputOrder.includes(input.key))
    .sort((a, b) => inputOrder.indexOf(a.key) - inputOrder.indexOf(b.key));

  return (
    <>
      {inputs.map((input) => {
        const { parameters } = input;
        const { label } = input;
        let inputLabel: string | JSX.Element = label;

        if (parameters?.tooltip) {
          inputLabel = (
            <Tooltip {...parameters.tooltip}>
              {label} <QuestionCircleOutlined />
            </Tooltip>
          );
        }

        return (
          <FilterInputListItem
            key={input.key}
            filterInput={{
              ...input,
              label: inputLabel,
            }}
            handleAddFilter={(key, value) => {
              setParameter(key, value);
            }}
            handleRemoveFilter={(_key) => {}}
          />
        );
      })}
    </>
  );
};

export function CaseManagementFilters(props: CaseManagementFiltersProps) {
  const { isMultiTenancyEnabled, states, statesLoading, type } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { parsed: params, setParameter, clearAll } = useSearchParams();
  const {
    data,
    loading: filtersLoading,
    error,
  } = useQuery<CaseManagementFilterOptionsQuery, CaseManagementFilterOptionsQueryVariables>(
    CASE_MANAGEMENT_FILTER_OPTIONS,
    {
      variables: {
        type,
      },
    },
  );
  const [fetchSubcategories, { data: subcategoryData, error: subcategoryError, loading: subcategoryLoading }] =
    useLazyQuery<SubcategoriesByCategoryQuery, SubcategoriesByCategoryQueryVariables>(SUBCATEGORIES_BY_CATEGORY_QUERY);

  const [codeOptions, setCodeOptions] = useState<FilterOption[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<FilterOption[]>([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState<FilterOption[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (error) {
      console.error(error.message);
      message.error('An error occurred while fetching filter options.');
    }
  }, [error]);

  useEffect(() => {
    if (subcategoryError) {
      console.error(subcategoryError.message);
      message.error('An error occurred while fetching subcategory options.');
    }
  }, [subcategoryError]);

  useEffect(() => {
    if (params.category) {
      fetchSubcategories({
        variables: {
          categoryValue: JSON.parse(params.category),
        },
      });
    }
  }, [params]);

  useEffect(() => {
    if (
      !data?.getCaseResolutionCodes?.length ||
      !data.getIncidentResolutionCodes?.length ||
      !data.caseManagementCategories
    )
      return;

    // Sort alphabetically
    const sorter = (a: FilterOption, b: FilterOption) => a.name.localeCompare(b.name);

    setCategoryOptions(Array.from(data.caseManagementCategories).sort(sorter));

    if (props.type === 'cases') {
      setCodeOptions(Array.from(data.getCaseResolutionCodes).sort(sorter));
    } else {
      setCodeOptions(Array.from(data.getIncidentResolutionCodes).sort(sorter));
    }
  }, [data, props.type]);

  useEffect(() => {
    if (!subcategoryData?.subcategoriesByCategory) return;

    setSubcategoryOptions(
      Array.from(subcategoryData.subcategoriesByCategory).sort((a, b) => a.name.localeCompare(b.name)),
    );
  }, [subcategoryData]);

  const assignmentGroups = Array.from(data?.caseManagementAssignmentGroups ?? []);

  const filterSelect = (input: string, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const commonSelectParams = {
    allowClear: true,
    filterOption: filterSelect,
    showSearch: true,
  };

  const dataType: DataType = {
    id: 'case-management-filter-tags',
    filterInputs: [
      {
        key: 'assignmentGroups',
        label: 'Assignment Groups',
        inputType: 'select',
        type: ActionFormFieldType.Array,
        parameters: {
          ...commonSelectParams,
          disabled: filtersLoading,
          loading: filtersLoading,
          maxTagCount: 'responsive',
          mode: 'multiple',
          options: assignmentGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((a) => ({ value: a.id, label: a.name })),
        },
      },
      {
        key: type === 'cases' ? 'resolutionCodes' : 'closeCodes',
        label: type === 'cases' ? 'Resolution Codes' : 'Close Codes',
        inputType: 'select',
        type: ActionFormFieldType.Array,
        parameters: {
          ...commonSelectParams,
          disabled: filtersLoading,
          loading: filtersLoading,
          maxTagCount: 'responsive',
          mode: 'multiple',
          options: codeOptions?.map((c) => ({ value: c.id, label: c.name })),
        },
      },
      {
        key: 'includeChildren',
        label: 'Include Child Clients',
        inputType: 'checkbox',
        type: ActionFormFieldType.Boolean,
        parameters: {
          tooltip: {
            title: `Include ${props.type} for all multi-tenant child locations`,
            placement: 'right',
          },
        },
      },
      {
        key: 'user',
        label: `Only My ${capitalize(props.type)}`,
        inputType: 'checkbox',
        type: ActionFormFieldType.Boolean,
        parameters: {
          tooltip: {
            title: getDirectlyAssociatedTooltipText(type),
            placement: 'right',
            children: getDirectlyAssociatedTooltipText(type),
          },
        },
      },
      {
        key: 'priorities',
        label: 'Priorities',
        inputType: 'select',
        type: ActionFormFieldType.Array,
        parameters: {
          ...commonSelectParams,
          maxTagCount: 'responsive',
          mode: 'multiple',
          options: Object.keys(priorities).map((p) => ({ value: p, label: p })),
        },
      },
      {
        key: 'states',
        label: 'States',
        inputType: 'select',
        type: ActionFormFieldType.Array,
        parameters: {
          ...commonSelectParams,
          disabled: statesLoading,
          loading: statesLoading,
          maxTagCount: 'responsive',
          mode: 'multiple',
          options: states.map((s) => ({ value: s, label: s })),
        },
      },
      {
        key: 'category',
        label: 'Category',
        inputType: 'select',
        type: ActionFormFieldType.String,
        parameters: {
          ...commonSelectParams,
          disabled: filtersLoading,
          loading: filtersLoading,
          options: categoryOptions.map((o) => ({ value: o.id, label: o.name })),
        },
      },
      {
        key: 'subcategory',
        label: 'Subcategory',
        inputType: 'select',
        type: ActionFormFieldType.String,
        parameters: {
          ...commonSelectParams,
          disabled: subcategoryLoading || !categoryOptions.length || !params.category,
          loading: subcategoryLoading,
          options: subcategoryOptions.map((o) => ({ value: o.id, label: o.name })),
        },
      },
    ],
  };

  const inputOrder = [
    'states',
    'priorities',
    'assignmentGroups',
    type === 'cases' ? 'resolutionCodes' : 'closeCodes',
    'user',
    'category',
    'subcategory',
  ];
  if (isMultiTenancyEnabled) {
    // first input
    inputOrder.splice(0, -1, 'includeChildren');
  }

  const updateSearchTag = () => {
    if (searchValue.length) {
      setParameter(SEARCH, searchValue);
      setSearchValue('');
    }
  };

  const deleteLastTag = () => {
    if (params[SEARCH]?.length) {
      // remove search tag.
      setParameter(SEARCH, null);
    } else if (dataType) {
      const tags = dataType.filterInputs?.filter((f) => params[f.key]?.length) ?? [];
      const lastTag = tags[tags.length - 1];

      if (lastTag) {
        setParameter(lastTag.key, null);
      }
    }
  };

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { value },
    } = event;

    setSearchValue(value);
  };

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      updateSearchTag();
    }

    if (key === 'Backspace' && searchValue.length === 0) {
      deleteLastTag();
    }
  };

  const onClick = ({ target }) => {
    if (target !== inputRef.current) {
      const isIE = (document.body.style as any).msTouchAction !== undefined;
      if (isIE) {
        setTimeout(() => {
          inputRef?.current?.focus();
        });
      } else {
        inputRef?.current?.focus();
      }
    }
  };

  return (
    <FiltersRoot>
      <ComplexSearchRoot className="complex-search">
        <Dropdown
          trigger={['click']}
          open={isDropdownVisible}
          onOpenChange={(isOpen) => setIsDropdownVisible(isOpen)}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <FilterDropdownRoot onClick={(e) => e.stopPropagation()}>
                    <FilterListRoot>
                      <FilterForm dataType={dataType} inputOrder={inputOrder} />
                    </FilterListRoot>
                  </FilterDropdownRoot>
                ),
                style: {
                  padding: 0,
                },
              },
            ],
            style: {
              padding: 0,
            },
          }}
        >
          <ComplexSearchSelector onClick={onClick}>
            <ComplexSearchSelectorOverflow>
              <ComplexSearchFilterButton onClick={() => setIsDropdownVisible(true)} />

              <FilterTags filterInputs={dataType.filterInputs!} />

              <SearchInputRoot>
                <Input ref={inputRef} value={searchValue} onChange={onInputChange} onKeyDown={handleInputKeyDown} />
              </SearchInputRoot>
            </ComplexSearchSelectorOverflow>

            <ClearFiltersButton onClick={clearAll} />
          </ComplexSearchSelector>
        </Dropdown>
      </ComplexSearchRoot>
    </FiltersRoot>
  );
}
