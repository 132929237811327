import { gql, useQuery } from '@apollo/client';
import { Checkbox, Dropdown, Modal, Space } from 'antd';
import { taskListPath } from 'components/case-management/paths';
import { EmptyState, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { Link } from 'react-router-dom';
import { ClientTaskCard } from 'components/client-tasks/client-task-card';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { QueryDataTypeFilter, RecentTasksCardQuery } from 'types/graph-codegen/graph-types';
import nuIconStories from 'components/nuspire/nu-icon/nu-icon.stories';
import { Ellipsis, Plus } from 'components/nuspire/nu-icon';
import { useState } from 'react';
import {
  CreateClientTaskForm,
  CreateClientTaskResult,
  CreateTaskInitialValues,
} from 'components/client-tasks/create-client-task';
import styled from 'styled-components';
import { client } from 'utils/graphql';

const RECENT_TASKS_CARD = gql`
  query RecentTasksCard($clientId: String!, $filters: [QueryDataTypeFilter], $next: String) {
    searchClientTasks(clientId: $clientId, filters: $filters, next: $next) {
      items {
        id
        type
        shortId
        clientId
        label
        status
        startDate
        endDate
        createdAt
        summary
        tags
        priority
        members
      }
      total
      next
    }
  }
`;

function TaskList(props: { tasks?: any[]; loading: boolean; onOpenCreateModal: () => void }) {
  const { tasks, loading } = props;

  if (tasks?.length) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        {tasks.map((task) => (
          <ClientTaskCard key={task.id} {...task} />
        ))}
      </Space>
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return (
    <EmptyState
      actions={
        <NuButton type="primary" onClick={props.onOpenCreateModal}>
          Create Task
        </NuButton>
      }
    >
      No Open Tasks were Found.
    </EmptyState>
  );
}

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
`;

export function RecentTasksCard(props: {
  clientId: string;
  title?: string;
  tags?: string[];

  // Props passed to Create Task Modal Form;
  createTaskInitialValues?: CreateTaskInitialValues;
}) {
  const { clientId, title = 'Recent Tasks', tags, createTaskInitialValues } = props;

  // State
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const handleOpenCreateModal = () => setCreateModalOpen(true);
  const handleCloseCreateModal = () => setCreateModalOpen(false);

  const [hideClosedTasks, setHideClosedTasks] = useState<boolean>(true);

  // TODO: Manage Status with state.

  const filters: QueryDataTypeFilter[] = [];

  if (hideClosedTasks) {
    filters.push({
      key: 'status',
      value: 'todo,in_progress,review,open',
    });
  }

  if (tags?.length) {
    filters.push({
      key: 'tags',
      value: tags.join(','),
    });
  }

  const { data, loading, refetch } = useQuery<RecentTasksCardQuery>(RECENT_TASKS_CARD, {
    variables: {
      clientId,
      filters,
    },
  });

  const tasks = data?.searchClientTasks?.items;

  const optionsDropdownButton = (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: '0',
            label: (
              <Link to={taskListPath({ clientId })}>
                <NuButton type="link">View All</NuButton>
              </Link>
            ),
          },
          {
            type: 'divider',
          },
          {
            key: '1',
            label: (
              <Checkbox checked={hideClosedTasks} onChange={(e) => setHideClosedTasks(e.target.checked)}>
                {'Hide Closed Tasks'}
              </Checkbox>
            ),
          },
        ],
      }}
    >
      <NuButton icon={<Ellipsis />} shape="circle" type="text" size="small" />
    </Dropdown>
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <NuCard fullHeight>
        <NuCardTitle
          title={title}
          actions={
            <>
              <NuButton icon={<Plus />} shape="circle" type="text" size="small" onClick={handleOpenCreateModal} />
              {optionsDropdownButton}
            </>
          }
        />
        <NuCardContent>
          <TaskList tasks={tasks} loading={loading} onOpenCreateModal={() => handleOpenCreateModal()} />
        </NuCardContent>
      </NuCard>

      <Modal
        open={createModalOpen}
        title="Create Task"
        footer={false}
        width={1100}
        styles={{
          body: {
            height: '70vh',
          },
        }}
        onCancel={handleCloseCreateModal}
        destroyOnClose={false}
      >
        <ModalLayout>
          <CreateClientTaskForm
            supportChildClientTaskCreation={false}
            initialValues={createTaskInitialValues}
            onSuccess={() => {
              refetch();
              handleCloseCreateModal();

              // This should cause the card list to refetch
              client.refetchQueries({
                include: ['WidgetData'],
              });
            }}
          />
        </ModalLayout>
      </Modal>
    </div>
  );
}
