import { gql, useMutation, useQuery } from '@apollo/client';
import { Form, Input, Select, Typography, message } from 'antd';
import { UnauthenticatedLayout } from 'components/layouts/unauthenticated-layout';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { personas } from '../personas';
import PartnerClientFormItems from './clients/partner-client-form-items';
import { alphabetizeIndustries } from './industries';
import { NuButton } from './nuspire';
import NuCard, { NuCardContent } from './nuspire/nu-card';
import { PhoneInputFormValues } from './phone-input';
import { TypographyLink } from './typography-link';

const { Option } = Select;

export const GET_INDUSTRIES = gql`
  query GetSignUpIndustry {
    signUpIndustries @client {
      name
      id
    }
  }
`;

const SIGNUP_MUTATION = gql`
  mutation SignUp($request: SignUpRequest!) {
    signUp(request: $request) @client {
      admin {
        id
        firstName
        lastName
        email
      }
      client {
        id
        name
      }
    }
  }
`;

const Title = styled(Typography.Title).attrs({
  level: 3,
})`
  && {
    color: ${(p) => p.theme.color.black};
  }
`;

export function SignUp() {
  const navigate = useNavigate();
  const [signUpMutation, { loading, error }] = useMutation(SIGNUP_MUTATION);
  const { data, loading: fetchingIndustries } = useQuery(GET_INDUSTRIES);
  const [isPartner, setIsPartner] = useState(false);
  // TODO: use geo-location to set a default.
  const defaultPartnerPhoneCountryCodeKey = null;
  const [partnerPhoneCountryCodeKey, setPartnerPhoneCountryCodeKey] = useState(defaultPartnerPhoneCountryCodeKey);

  const alphabetizedIndustries = alphabetizeIndustries(data?.signUpIndustries);
  const filterSelect = (input: string, option: any) => {
    return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
  };

  useEffect(() => {
    if (error) {
      console.error(error);
      message.error('An error occurred while attempting to sign up.');
    }
  }, [error]);

  return (
    <div>
      <NuCard title="Get Started With Nuspire">
        <NuCardContent>
          <div style={{ marginBottom: '1.5rem' }}>
            <p>Cybersecurity redefined. Powered by us, customized for you.</p>
          </div>
          <Form
            style={{ marginBottom: '1.5rem' }}
            layout="vertical"
            onFinish={async (formValues) => {
              const {
                companyName,
                email,
                firstName,
                industryId,
                lastName,
                persona,
                isClientPartner = false,
                partnerType,
                partnerBusinessType,
                partnerCountry,
                partnerPhone,
                partnerSalesRep,
                partnerWebsite,
                partnerEmail,
                partnerAdditionalInfo,
              } = formValues;

              let partnerDetails;
              if (isClientPartner) {
                const { countryCodeKey, number, extension } = partnerPhone as PhoneInputFormValues;
                const [, partnerPhoneCountryCode] = countryCodeKey.split('-');
                const businessPhone = number!.replace(/\D/g, '');

                partnerDetails = {
                  additionalInfo: partnerAdditionalInfo,
                  businessCountry: partnerCountry,
                  businessEmail: partnerEmail,
                  businessPhone: `+${partnerPhoneCountryCode} ${businessPhone}`,
                  businessPhoneExt: extension,
                  businessSalesRepresentative: partnerSalesRep,
                  businessType: partnerBusinessType,
                  businessWebsite: partnerWebsite,
                  partnerType,
                };
              }

              try {
                await signUpMutation({
                  variables: {
                    request: {
                      firstName,
                      lastName,
                      email,
                      industryId,
                      companyName,
                      persona,
                      isPartner,
                      partnerDetails,
                    },
                  },
                });

                navigate('/sign-up/confirmation');
              } catch (err: any) {
                console.error(`Sign up failed.`, err);
              }
            }}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please enter your first name', pattern: /^[a-zA-Z]+$/ }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please enter your last name', pattern: /^[a-zA-Z]+$/ }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ type: 'email', required: true, message: 'Please enter a valid e-mail address' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Industry"
              name="industryId"
              rules={[{ required: true, message: 'Please select your industry' }]}
            >
              <Select size="large" loading={fetchingIndustries} showSearch filterOption={filterSelect}>
                {alphabetizedIndustries.map((industry) => (
                  <Option key={industry.id} value={industry.id}>
                    {industry.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[{ required: true, message: 'Please enter your company name' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Persona or Role"
              name="persona"
              rules={[{ required: true, message: 'Please select your company role' }]}
            >
              <Select size="large" showSearch filterOption={filterSelect} options={personas} />
            </Form.Item>
            <PartnerClientFormItems
              initialValues={{}}
              inlineCheckbox
              isPartner={isPartner}
              partnerPhoneCountryCodeKey={partnerPhoneCountryCodeKey ?? undefined}
              setIsPartner={setIsPartner}
            />
            <NuButton type="primary" htmlType="submit" size="large" style={{ width: '100%' }} loading={loading}>
              <Typography.Text strong style={{ color: 'white' }}>
                Sign up
              </Typography.Text>
            </NuButton>
          </Form>
          <div>
            <span>
              Nuspire is committed to your privacy and will only use the information you provide to us to contact you
              about our relevant content, products, and services. You may unsubscribe from these communications at any
              time. By clicking sign up, you are giving consent to the storing and processing of your personal data by
              Nuspire as described in our{' '}
            </span>
            <Typography.Link href="https://www.nuspire.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Typography.Link>{' '}
            and{' '}
            <Typography.Link href="https://www.nuspire.com/terms" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </Typography.Link>
          </div>
          <div style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
            <span>Already have an account? </span>
            <TypographyLink to="login">Sign In</TypographyLink>
          </div>
        </NuCardContent>
      </NuCard>
    </div>
  );
}

const SignUpPage = () => {
  return (
    <UnauthenticatedLayout>
      <Helmet title="Sign Up" />
      <SignUp />
    </UnauthenticatedLayout>
  );
};

export default SignUpPage;
